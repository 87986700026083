import React from "react";
import { Box, CardMedia, Grid, Container, Typography } from "@mui/material";

export default function ImagesGallery(props) {
  const { component } = props;

  const { fscope } = component.props;
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const sliders = component.getData("default.sliders", []);
  const imgVersion = component.getHelpers("value").getValue("images-version");

  return (
    <Container disableGutters sx={{ my: 3 }}>
      <Grid
        container
        justifycontent="center"
        direction="row"
        rowSpacing={3}
        columnSpacing={1}
      >
        {sliders.map((slider) => {
          const { slug = null, button_text } = devHelper.getObjectValue(
            slider,
            "_values"
          );

          return (
            <Grid item md={3} sm={4} xs={6} key={slug}>
              <Box sx={{ border: "5px solid #9e9f93", height: "100%" }}>
                <CardMedia
                  component="img"
                  alt={component.trans(`${fscope} image`)}
                  image={filesUrl.concat(
                    `/${fscope}/${slug}.jpg?v=${imgVersion}&tr=w-700,h-900,c-maintain_ratio`
                  )}
                />
                <Typography
                  align="center"
                  sx={{
                    fontFamily: "Poppins-Bold",
                    fontSize: 14,
                    py: 1.5,
                    px: 1,
                  }}
                >
                  {button_text}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}

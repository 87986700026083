import React, { useLayoutEffect, useState, useRef } from "react";
import { Grid, Stack, Skeleton } from "@mui/material";

export default function Poduct_List_Skeleton_View(props) {
  const targetRef = useRef();

  function SetImageHeight() {
    const [height, setHeight] = useState(0);
    useLayoutEffect(() => {
      function updateSize() {
        if (targetRef.current) {
          setHeight(900 * ((targetRef.current.clientWidth - 4) / 720));
        }
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return height;
  }
  const img_height = SetImageHeight();

  return (
    <Grid item xs={6} sm={6} md={4} justifycontent="center" ref={targetRef}>
      <Skeleton variant="rectangular" width="100%" height={img_height} />
      <Stack
        spacing={0}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Skeleton variant="text" height={21} width={100} />
        <Skeleton variant="text" height={25.5} width={160} />
        <Skeleton variant="text" height={18} width={120} />
      </Stack>
    </Grid>
  );
}

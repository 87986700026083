import React from "react";
import {
  SwipeableDrawer,
  Link,
  Box,
  Typography,
  Grid,
  Button,
  Stack,
  IconButton,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

export default function SubMenuDrawer(props) {
  const { component } = props;
  const theme = useTheme();
  const menu = component.getData("default.menu", null);

  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const imgVersion = component.getHelpers("value").getValue("images-version");

  const mainMenu = component.getMainMenu();

  const newIn = mainMenu.find((menu) => menu.slug === "new-in");
  const womens = mainMenu.find((menu) => menu.slug === "womens");
  const mens = mainMenu.find((menu) => menu.slug === "mens");
  const gifts = mainMenu.find((menu) => menu.slug === "gifts");

  const closeAllDrawers = () => {
    component.toggleMenuDrawer();
    component.toggle();
  };

  return (
    <SwipeableDrawer
      anchor="left"
      open={component.getData("default.opened", false)}
      onClose={() => closeAllDrawers()}
      onOpen={() => {}}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 3,
        "& .MuiDrawer-paper": {
          p: 0,
          overflowY: "scroll",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          width: { xs: "100%", sm: "500px" },
          flexDirection: "column",
          justifyContent: "space-between",
        },
        "& .MuiDrawer-paper::-webkit-scrollbar": {
          display: "none",
        },
      }}
      transitionDuration={{ enter: 1000, exit: 500 }}
      swipeAreaWidth={0}
    >
      <Stack
        direction="row"
        sx={{
          alignItems: "stretch",
          justifyContent: "space-between",
          bgcolor: "#fff",
          px: 2,
        }}
      >
        <IconButton
          onClick={() => component.toggle()}
          aria-label="back"
          color="primary"
        >
          {/* <i className="fa-regular fa-chevron-left" /> */}
          <i className="fa-regular fa-arrow-left" />
        </IconButton>
        <Stack
          sx={{
            height: {
              xs: theme.appbar_height.mobile,
              md: theme.appbar_height.desktop,
            },
            justifyContent: "center",
          }}
        >
          {menu && (
            <Typography
              component={RouterLink}
              to={devHelper.getObjectValue(menu, "url")}
              onClick={() => closeAllDrawers()}
              sx={{
                fontFamily: "Spartan-Bold",
                color: "#000",
                fontSize: 20,
                textDecoration: "none",
                lineHeight: 1,
                mb: -0.5,
              }}
            >
              {devHelper.getObjectValue(menu, "name")}
            </Typography>
          )}
        </Stack>
        <IconButton
          onClick={() => closeAllDrawers()}
          aria-label="close dialog"
          color="primary"
        >
          <i className="fa-regular fa-xmark" />
        </IconButton>
      </Stack>
      <Box sx={{ p: 2, mt: 2, flexGrow: 1 }}>
        <Box>
          {devHelper.getObjectValue(menu, "slug") === "new-in" && (
            <Grid container direction="row" spacing={2}>
              {devHelper
                .getObjectValue(newIn, "subMenu.curation", [])
                .map((data) => {
                  const { slug, title = "", button = "", url } = data;

                  return (
                    <Grid item xs={12} key={`new-in-${slug}`}>
                      <Box
                        key={slug}
                        sx={{
                          textAlign: "center",
                          "&:hover": {
                            "& > div > a > img": {
                              transform: "scale(1.3)",
                              webkitTransform: "scale(1.3)",
                            },
                          },
                        }}
                      >
                        <Box
                          className="box-img-zoomed"
                          sx={{
                            overflow: "hidden",
                            position: "relative",
                          }}
                        >
                          <Link
                            component={RouterLink}
                            to={url}
                            onClick={() => closeAllDrawers()}
                            sx={{
                              img: {
                                width: "100%",
                                height: "auto",
                                transition: "transform .5s ease",
                                webkitTransition: "transform .5s ease",
                              },
                            }}
                          >
                            <img
                              src={`${filesUrl}/marketing/menu/new-in/${slug}.jpg?v=${imgVersion}&tr=w-700`}
                              alt={title}
                            />
                          </Link>
                          <Stack
                            spacing={0.5}
                            sx={{
                              position: "absolute",
                              bottom: 0,
                              left: 0,
                              pl: 1.5,
                              pb: 3,
                              alignItems: "flex-start",
                            }}
                          >
                            <Link
                              component={RouterLink}
                              to={url}
                              variant="text"
                              sx={{
                                fontFamily: "Spartan-Bold",
                                lineHeight: 1,
                                fontSize: 18,
                                textDecoration: "none",
                                color: "#fff",
                              }}
                              onClick={() => closeAllDrawers()}
                            >
                              {title}
                            </Link>
                            <Button
                              component={RouterLink}
                              to={url}
                              sx={{
                                color: "#fff",
                                fontSize: 13,
                                borderBottom: "1px solid #fff",
                                borderRadius: 0,
                                fontWeight: 600,
                                lineHeight: 1,
                                p: 0,
                                "&:hover,&:focus": {
                                  backgroundColor: "transparent",
                                  borderBottom: "1px solid transparent",
                                },
                              }}
                              disableRipple
                            >
                              {button}
                            </Button>
                          </Stack>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
            </Grid>
          )}
          {devHelper.getObjectValue(menu, "slug") === "womens" && (
            <Grid
              container
              direction="row"
              columnSpacing={2}
              rowSpacing={6}
              sx={{ py: 2, justifyContent: "center" }}
            >
              {Object.entries(
                devHelper.getObjectValue(womens, "subMenu", {})
              ).map(([key, value]) => {
                return (
                  <Grid item xs={6} key={key}>
                    <Typography
                      align="center"
                      sx={{
                        fontFamily: "Poppins-SemiBold",
                        color: "#000",
                        fontSize: 19,
                        textTransform: "uppercase",
                        mb: 2,
                      }}
                    >
                      {key}
                    </Typography>
                    <Stack spacing={2}>
                      {value.map((val) => {
                        const { slug, title = "", url } = val;
                        return (
                          <Link
                            key={`${key}-${slug}`}
                            component={RouterLink}
                            align="center"
                            to={url}
                            onClick={() => closeAllDrawers()}
                            sx={{
                              fontFamily: "Spartan-Bold",
                              color: "#000",
                              fontSize: 12,
                              textTransform: "uppercase",
                              textDecoration: "none",
                            }}
                          >
                            {title}
                          </Link>
                        );
                      })}
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
          )}
          {devHelper.getObjectValue(menu, "slug") === "mens" && (
            <Grid
              container
              direction="row"
              columnSpacing={2}
              rowSpacing={6}
              sx={{ py: 2, justifyContent: "center" }}
            >
              {Object.entries(
                devHelper.getObjectValue(mens, "subMenu", {})
              ).map(([key, value]) => {
                return (
                  <Grid item xs={6} key={key}>
                    <Typography
                      align="center"
                      sx={{
                        fontFamily: "Poppins-SemiBold",
                        color: "#000",
                        fontSize: 19,
                        textTransform: "uppercase",
                        mb: 2,
                      }}
                    >
                      {key}
                    </Typography>
                    <Stack spacing={2}>
                      {value.map((val) => {
                        const { slug, title = "", url } = val;
                        return (
                          <Link
                            key={`${key}-${slug}`}
                            component={RouterLink}
                            align="center"
                            to={url}
                            onClick={() => closeAllDrawers()}
                            sx={{
                              fontFamily: "Spartan-Bold",
                              color: "#000",
                              fontSize: 12,
                              textTransform: "uppercase",
                              textDecoration: "none",
                            }}
                          >
                            {title}
                          </Link>
                        );
                      })}
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
          )}
          {devHelper.getObjectValue(menu, "slug") === "gifts" && (
            <Grid
              container
              direction="row"
              spacing={1}
              sx={{ py: 2, justifyContent: "center" }}
            >
              <Grid item xs={12}>
                <Box
                  sx={{
                    textAlign: "center",
                    "&:hover": {
                      "& > div > a > img": {
                        transform: "scale(1.3)",
                        webkitTransform: "scale(1.3)",
                      },
                    },
                  }}
                >
                  <Box
                    className="box-img-zoomed"
                    sx={{
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    <Link
                      component={RouterLink}
                      to="/gifts"
                      onClick={() => closeAllDrawers()}
                      sx={{
                        img: {
                          width: "100%",
                          height: "auto",
                          transition: "transform .5s ease",
                          webkitTransition: "transform .5s ease",
                        },
                      }}
                    >
                      <img
                        src={`${filesUrl}/marketing/menu/gifts/womens-gifts.jpg?v=${imgVersion}&tr=w-700`}
                        alt="WOMEN’S GIFTS"
                      />
                    </Link>
                    <Stack
                      spacing={0.5}
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        pl: 1.5,
                        pb: 3,
                        alignItems: "flex-start",
                      }}
                    >
                      <Link
                        component={RouterLink}
                        to="/gifts"
                        variant="text"
                        sx={{
                          fontFamily: "Spartan-Bold",
                          lineHeight: 1,
                          fontSize: 18,
                          textDecoration: "none",
                          color: "#fff",
                        }}
                        onClick={() => closeAllDrawers()}
                      >
                        WOMEN’S GIFTS
                      </Link>
                      <Button
                        component={RouterLink}
                        to="/gifts"
                        sx={{
                          color: "#fff",
                          fontSize: 13,
                          borderBottom: "1px solid #fff",
                          borderRadius: 0,
                          fontWeight: 600,
                          lineHeight: 1,
                          p: 0,
                          minWidth: 0,
                          "&:hover,&:focus": {
                            backgroundColor: "transparent",
                            borderBottom: "1px solid transparent",
                          },
                        }}
                        disableRipple
                      >
                        EXPLORE
                      </Button>
                    </Stack>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  spacing={0.5}
                  sx={{ justifyContent: "center" }}
                >
                  {Object.entries(
                    devHelper.getObjectValue(gifts, "womensMenu", {})
                  ).map(([key, value]) => {
                    return (
                      <Grid item xs={4} key={key}>
                        <Typography
                          align="center"
                          sx={{
                            fontFamily: "Poppins-SemiBold",
                            color: "#000",
                            fontSize: 19,
                            textTransform: "uppercase",
                            mb: 2,
                          }}
                        >
                          {key}
                        </Typography>
                        <Stack spacing={2}>
                          {value.map((val) => {
                            const { slug, title = "", url } = val;
                            return (
                              <Link
                                key={`${key}-${slug}`}
                                component={RouterLink}
                                align="center"
                                to={url}
                                onClick={() => closeAllDrawers()}
                                sx={{
                                  fontFamily: "Spartan-Bold",
                                  color: "#000",
                                  fontSize: 12,
                                  textTransform: "uppercase",
                                  textDecoration: "none",
                                }}
                              >
                                {title}
                              </Link>
                            );
                          })}
                        </Stack>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    textAlign: "center",
                    "&:hover": {
                      "& > div > a > img": {
                        transform: "scale(1.3)",
                        webkitTransform: "scale(1.3)",
                      },
                    },
                  }}
                >
                  <Box
                    className="box-img-zoomed"
                    sx={{
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    <Link
                      component={RouterLink}
                      to="/gifts"
                      onClick={() => closeAllDrawers()}
                      sx={{
                        img: {
                          width: "100%",
                          height: "auto",
                          transition: "transform .5s ease",
                          webkitTransition: "transform .5s ease",
                        },
                      }}
                    >
                      <img
                        src={`${filesUrl}/marketing/menu/gifts/mens-gifts.jpg?v=${imgVersion}&tr=w-700`}
                        alt="MEN’S GIFTS"
                      />
                    </Link>
                    <Stack
                      spacing={0.5}
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        pl: 1.5,
                        pb: 3,
                        alignItems: "flex-start",
                      }}
                    >
                      <Link
                        component={RouterLink}
                        to="/gifts"
                        variant="text"
                        sx={{
                          fontFamily: "Spartan-Bold",
                          lineHeight: 1,
                          fontSize: 18,
                          textDecoration: "none",
                          color: "#fff",
                        }}
                        onClick={() => closeAllDrawers()}
                      >
                        MEN’S GIFTS
                      </Link>
                      <Button
                        component={RouterLink}
                        to="/gifts"
                        sx={{
                          color: "#fff",
                          fontSize: 13,
                          borderBottom: "1px solid #fff",
                          borderRadius: 0,
                          fontWeight: 600,
                          lineHeight: 1,
                          p: 0,
                          minWidth: 0,
                          "&:hover,&:focus": {
                            backgroundColor: "transparent",
                            borderBottom: "1px solid transparent",
                          },
                        }}
                        disableRipple
                      >
                        EXPLORE
                      </Button>
                    </Stack>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  spacing={0.5}
                  sx={{ justifyContent: "center" }}
                >
                  {Object.entries(
                    devHelper.getObjectValue(gifts, "mensMenu", {})
                  ).map(([key, value]) => {
                    return (
                      <Grid item xs={4} key={key}>
                        <Typography
                          align="center"
                          sx={{
                            fontFamily: "Poppins-SemiBold",
                            color: "#000",
                            fontSize: 19,
                            textTransform: "uppercase",
                            mb: 2,
                          }}
                        >
                          {key}
                        </Typography>
                        <Stack spacing={2}>
                          {value.map((val) => {
                            const { slug, title = "", url } = val;
                            return (
                              <Link
                                key={`${key}-${slug}`}
                                component={RouterLink}
                                align="center"
                                to={url}
                                onClick={() => closeAllDrawers()}
                                sx={{
                                  fontFamily: "Spartan-Bold",
                                  color: "#000",
                                  fontSize: 12,
                                  textTransform: "uppercase",
                                  textDecoration: "none",
                                }}
                              >
                                {title}
                              </Link>
                            );
                          })}
                        </Stack>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
      <Stack
        direction="row"
        spacing={{ xs: 2, sm: 3 }}
        sx={{
          bgcolor: "#9e9f93",
          py: 2,
          justifyContent: "center",
          "& a": {
            color: "#fff",
            fontSize: 13,
            borderRadius: 0,
            fontWeight: 600,
            lineHeight: 1,
            p: 0,
            "&:hover,&:focus": {
              backgroundColor: "transparent",
              borderBottom: "2px solid #fff",
            },
          },
        }}
      >
        <Button to="/contact-us" component={RouterLink} disableRipple>
          CONTACT US
        </Button>
        <Button to="/boutiques" component={RouterLink} disableRipple>
          STORE LOCATOR
        </Button>
        <Button to="/trunk-shows" component={RouterLink} disableRipple>
          ANNOUCMENTS
        </Button>
      </Stack>
    </SwipeableDrawer>
  );
}

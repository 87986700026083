import React from "react";
import { AppBar, Toolbar, Container, Stack, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

export default function CheckoutNavBar(props) {
  const { component } = props;

  const theme = useTheme();

  const filesUrl = component.getHelpers("env").get("files_url");
  const imgVersion = component.getHelpers("value").getValue("images-version");

  return (
    <AppBar position="relative" sx={{ boxShadow: "none" }}>
      <Toolbar disableGutters sx={{ bgcolor: "#fff" }}>
        <Container maxWidth="xl" disableGutters>
          <Stack
            direction="row"
            sx={{
              px: 2,
              justifyContent: "center",
              alignItems: "center",
              height: {
                xs: theme.appbar_height.mobile,
                md: theme.appbar_height.desktop,
              },
            }}
          >
            <Button
              sx={{
                outline: "none",
                p: 0,
                "&:hover": {
                  bgcolor: "transparent",
                },
                img: { width: { xs: 75, md: 95 } },
              }}
              disableRipple
              component={RouterLink}
              to="/"
            >
              <img
                src={`${filesUrl}/logos/black/square.png?v=${imgVersion}&tr=w-350`}
                srcSet={`${filesUrl}/logos/black/square.png?v=${imgVersion}&tr=w-300 600w, ${filesUrl}/logos/black/square.png?v=${imgVersion}&tr=w-350 900w`}
                sizes="(max-width: 600px) 300px, (max-width: 900px) 350px"
                alt="Konstantino Home"
                title="Konstantino Home"
              />
            </Button>
          </Stack>
        </Container>
      </Toolbar>
    </AppBar>
  );
}

import React from "react";
import { Container, Grid, Typography, CardMedia, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins-Regular",
  color: "#fff",
  fontSize: "34px",
  lineHeight: 1,
}));

const Paragraph = styled(Typography)(({ theme }) => ({
  fontFamily: "Spartan-Medium",
  color: "#fff",
  fontSize: "18px",
  marginTop: "16px",
  textAlign: "justify",
}));

export default function Films(props) {
  const { component } = props;
  const filesUrl = component.getHelpers("env").get("files_url");
  const imgVersion = component.getHelpers("value").getValue("images-version");

  const sections = [
    {
      slug: "aegean",
      title: "Aegean Collection",
      text: "The Aegean Collection draws its inspiration from the captivating beauty of the Aegean Sea and its surrounding coastal landscapes. With its azure waters, pristine beaches, and picturesque islands, the Aegean region is renowned for its breathtaking natural beauty and rich cultural heritage.",
    },
    {
      slug: "arsinoe",
      title: "Arsinoe Collection",
      text: "Capturing the essence of Queen Arsinoe II's extraordinary life and the grandeur of the Hellenistic period. Through exquisite craftsmanship, thoughtful design, and a nod to historical influences, the collection offers a jewelry experience that is both captivating and imbued with a sense of royal elegance.",
    },
    {
      slug: "amphitrite",
      title: "Amphitrite Collection",
      text: "Known for her beauty, grace, and connection to the ocean depths, Greek goddess; Amphitriti embodies the power and allure of the sea. The collection captures the essence of her mythological presence and the captivating beauty of the underwater world.",
    },
    {
      slug: "aspasia",
      title: "Aspasia Collection",
      text: "This Sterling Silver and Gold Collection celebrates the intellectual brilliance, elegance, and strength of Aspasia. By blending historical inspiration with exquisite craftsmanship, the collection offers jewelry pieces that embody her timeless influence and invite wearers to embrace their own intellectual and feminine power.",
    },
    {
      slug: "asteri",
      title: "Asteri Collection",
      text: "Inspiration drawn from the celestial beauty and timeless allure of the stars. The collection captures the enchanting contrast between the darkness of the night sky and the radiant brilliance of the stars. It combines the sophistication of black diamonds with the classic elegance of white diamonds, creating a captivating and striking aesthetic.",
    },
    {
      slug: "astritis",
      title: "Astritis Collection",
      text: "Through thoughtful design, exquisite craftsmanship, and the incorporation of celestial elements, the collection offers jewelry pieces that evoke a sense of wonder, capture the imagination, and allow wearers to carry vibrant colors and swirling patterns dancing across the night sky. The Astritis Collection captures the essence of a celestial spectacle, translating its captivating allure into exquisite jewelry pieces.",
    },
    {
      slug: "byzantium",
      title: "Byzantium Collection",
      text: "The Byzantium Collection captures the essence of this iconic period, fusing it with modern design elements. Through intricate designs and a nod to historical influences, the collection offers jewelry pieces that evoke a sense of timeless elegance, sophistication, and a connection to one of the most influential periods in history.",
    },
    {
      slug: "cross-classics",
      title: "Cross Classics",
      text: "The Sterling Silver and Gold Cross Classics draws its inspiration from the timeless symbol of the cross, which has held deep meaning and significance across cultures and generations. By enduring beauty, spirituality, and elegance of the cross, incorporating sterling silver and gold to create exquisite pieces that honor this iconic symbol.",
    },
    {
      slug: "womens-diamond-classics",
      title: "Women’s Diamond Classics",
      text: "Diamond Classics embody timeless elegance, offering jewelry pieces that transcend trends and fads. Drawing inspiration from K-Classic designs, the sector showcases delicate and sophisticated pieces that never go out of style. Whether it's a pendant, a diamond bracelet, or a pair of stud earrings, these pieces exude an enduring grace that can be cherished for a lifetime.",
    },
    {
      slug: "electra",
      title: "Electra Collection",
      text: "Electra in Greek mythology, known for her strength, resilience, and determination. This collection captures the essence of Electra's story and personality, infusing it with modern design elements to create bold and empowering jewelry pieces.",
    },
    {
      slug: "erato",
      title: "Erato Collection",
      text: "Erato was the muse of love poetry in Greek mythology, Erato. Known for her enchanting beauty and lyrical nature, Erato embodies the essence of romance, passion, and artistic expression. This collection celebrates love and captures the captivating allure of Erato through exquisite jewelry designs.",
    },
    {
      slug: "flamenco-gold",
      title: "Flamenco Gold Collection",
      text: "The Flamenco Gold Collection draws its inspiration from the passionate and vibrant art form of flamenco, known for its captivating music, energetic dance movements, and striking costumes. This collection celebrates the spirit of flamenco through exquisite designs that showcase the intense beauty of London Blue Topaz gemstones combined with the opulence of gold.",
    },
    {
      slug: "flamenco",
      title: "Flamenco Collection",
      text: "Flamenco is synonymous with vibrant colors where signature designs reflect this by incorporating dramatic silhouettes. Statement necklaces, voluminous rings, and striking bracelets may feature elaborate detailing, capturing the attention and creating a powerful visual impact.",
    },
    {
      slug: "kassandra",
      title: "Kassandra Collection",
      text: "Kassandra was known for her beauty, wisdom, and prophetic abilities. This collection celebrates her mystique and elegance through exquisite designs that showcase the timeless beauty of pearls. Epitomizes timeless elegance, offering jewelry pieces that transcend trends and fashion cycles. The result is jewelry that exudes grace and sophistication, becoming cherished heirlooms that can be passed down through generations.",
    },
    {
      slug: "kerma",
      title: "Kerma Collection",
      text: "The Kerma Collection incorporates the historical and cultural significance of Kerma with Greek coinage. This collection celebrates the exchange of ideas, trade, and cultural interactions between the ancient civilizations.",
    },
    {
      slug: "mens-diamond-classics",
      title: "Men’s Diamond Classics",
      text: "Capturing the essence of masculine strength and power through bold and distinctive designs. Geometric shapes, clean lines, and angular patterns are incorporated into the jewelry pieces, reflecting the strength and confidence of the modern man.",
    },
    {
      slug: "minos",
      title: "Minos Collection",
      text: "The Minos Collection draws its inspiration from the ancient Minoan civilization that flourished on the island of Crete. Showcasing the rich artistic heritage, vibrant culture, and sophisticated craftsmanship of the Minoans, translating their influence into captivating jewelry designs.",
    },
    {
      slug: "myrmidones",
      title: "Myrmidones Collection",
      text: "The collection draws its inspiration from the legendary warriors known as the Myrmidons, who were prominent in Greek mythology and associated with their unwavering loyalty, strength, and martial prowess. This collection pays homage to the heroic and noble spirit of the Myrmidons through distinctive and bold jewelry designs.",
    },
    {
      slug: "nykta",
      title: "Nykta Collection",
      text: "Through celestial motifs, midnight colors, luminescent gems, and whimsical elements, this collection offers jewelry pieces that evoke a sense of mystery, magic, and wonder. Each piece invites the wearer to embrace the nocturnal realm and find their own personal connection to the mystical allure of the night.",
    },
    {
      slug: "paganini",
      title: "Paganini Collection",
      text: "The Paganini Jewelry Collection draws its inspiration from the renowned Italian composer and violin virtuoso, Niccolò Paganini. Known for his extraordinary talent, passion, and captivating performances, Paganini's music and persona serve as the driving force behind this collection.",
    },
    {
      slug: "plato",
      title: "Plato Collection",
      text: "The Plato Collection draws its inspiration from the profound philosophy and intellectual legacy of the ancient Greek philosopher, Plato. This collection seeks to capture the essence of Plato's ideas, wisdom, and pursuit of truth through thoughtful and symbolic jewelry designs.",
    },
    {
      slug: "poseidon",
      title: "Poseidon Collection",
      text: "Strong metalwork, bold designs, or motifs associated with power and authority can be featured, capturing the commanding presence of Poseidon. Power and beauty of the sea through inspired designs and symbolic elements. By incorporating nautical motifs, sea-inspired colors, and evocative designs, this collection offers jewelry pieces that allow wearers to embrace the allure and enchantment of Poseidon's realm.",
    },
    {
      slug: "soul",
      title: "Soul Collection",
      text: "The Soul Collection celebrates the depth, beauty, and spirituality of the soul music. A legacy collection with meaningful symbols, geometry, gemstones, and personalized elements. A self- reflection of Konstantino’s signature designing universe.",
    },
    {
      slug: "sparta",
      title: "Sparta Collection",
      text: "Inspired from the legendary city-state of Sparta, known for its fierce warriors, discipline, and indomitable spirit. This collection celebrates the strength, courage, and stoic beauty of the Spartan culture, translating its essence into powerful and striking jewelry designs.",
    },
    {
      slug: "thalassa",
      title: "Thalassa Collection",
      text: "This collection embodies the captivating beauty and mythical allure of the sea. This collection celebrates the essence of Thalassa, the ancient Greek primordial goddess of the sea, embodying the power, tranquility, and ever-changing nature of the Grecian Sea.",
    },
    {
      slug: "womens-sterling-silver-gold-classics",
      title: "Women’s Sterling Silver and Gold Classics",
      text: "The combination of sterling silver and gold creates a harmonious and luxurious contrast. Offers women a range of exquisite pieces that can be cherished for years to come. The Konstantino core signature two-tone designs, the bold and expressive nature of statement jewelry.",
    },
    {
      slug: "womens-sterling-silver-gold-classics-2",
      title: "Women’s Sterling Silver and Gold Classics",
      text: "Through sculptural elements, nature-inspired motifs, gemstone accents, mixed metals, geometric shapes, and symbolic meanings, K- Classics offer women a range of striking rings that make a statement and reflect their unique style and personality.",
    },
  ];

  return (
    <Box sx={{ bgcolor: "#000" }}>
      <Container
        disableGutters
        maxWidth="xl"
        sx={{ px: { xs: 1, sm: 2, md: 14 }, mb: 9, py: 10 }}
      >
        {/* text-section-1 */}
        <Grid
          container
          sx={{
            alignItems: "center",
            justifyContent: "center",
            mb: 10,
          }}
          rowSpacing={{ xs: 6, md: 12 }}
          columnSpacing={6}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Paragraph
              component="h3"
              align="center"
              sx={{
                fontSize: 20,
                maxWidth: 950,
                mx: "auto",
              }}
            >
              “This page showcases the creative vision and commitment to
              crafting exquisite pieces that stand the test of time. I believe
              that jewelry has the power to tell stories, evoke emotions, and
              leave a lasting legacy.”
              <Box
                component="span"
                sx={{
                  mt: 4,
                  fontSize: 20,
                  float: "right",
                }}
              >
                - Konstantino
              </Box>
            </Paragraph>
          </Grid>
        </Grid>

        {/* collections */}
        {sections.map((section, i) => {
          const { slug, title, text } = section;
          return (
            <Grid
              container
              key={`${slug}-${i}`}
              direction={
                (i + 1) % 2 === 0 ? { xs: "row", md: "row-reverse" } : "row"
              }
              sx={{
                alignItems: "center",
                justifyContent: "center",
                mb: 12,
              }}
              rowSpacing={{ xs: 2, md: 12 }}
              columnSpacing={2}
            >
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardMedia
                  component="img"
                  alt="about-image-1"
                  image={`${filesUrl}/marketing/k-world/legacy/${slug}.jpg?v=${imgVersion}&tr=w-1200,h-900,c-maintain_ratio`}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Title
                  component="h2"
                  sx={{
                    textAlign: {
                      xs: "left",
                      md: (i + 1) % 2 === 0 ? "right" : "left",
                    },
                  }}
                >
                  {title}
                </Title>
                <Paragraph component="h3">{text}</Paragraph>
              </Grid>
            </Grid>
          );
        })}
        {/* text-section-2 */}
        <Grid
          container
          sx={{
            alignItems: "center",
            justifyContent: "center",
          }}
          rowSpacing={{ xs: 6, md: 12 }}
          columnSpacing={6}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Paragraph
              component="h3"
              align="center"
              sx={{
                fontSize: 20,
                maxWidth: 1200,
                mx: "auto",
              }}
            >
              “These are some of my collections that are a testament to my
              design philosophy, which emphasizes the highest standards of
              craftsmanship, attention to detail, and innovation. Each piece I
              create is a labor of love, combining my technical expertise with a
              deep passion for storytelling through jewelry. I am dedicated to
              delivering not only stunning designs but also exceptional quality
              that ensures the longevity of each piece as a cherished heirloom.”
              <Box
                component="span"
                sx={{
                  mt: 4,
                  fontSize: 20,
                  float: "right",
                }}
              >
                - Konstantino
              </Box>
            </Paragraph>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

import React from "react";
import { styled } from "@mui/material/styles";
import {
  ListItem,
  ListItemAvatar,
  CardMedia,
  Link,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Box,
  Skeleton,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const MyTableCell = styled(TableCell)({
  padding: "0px 0px 8px",
  borderBottom: "none",
  fontFamily: "Spartan-Medium",
});

const ProductName = styled(Typography)({
  fontFamily: "Poppins-Regular",
  fontSize: "18px",
});

const ProductCollection = styled(Button)({
  fontFamily: "Spartan-SemiBold",
  fontSize: "12px",
  padding: "0px",
  minWidth: 0,
  "&:hover, &:focus": {
    background: "transparent",
  },
});

export default function NavBar(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");

  const product = component.getData("default.product", null);
  const image = component.getData("default.image", null);

  const wishlist = component.getData("default.wishlist", false);
  const in_stock = component.getData("default.in_stock", true);

  const gem = devHelper.getObjectValue(product, "gem");
  const size = devHelper.getObjectValue(product, "size");
  const sku = devHelper.getObjectValue(product, "p_sku");
  const simple = devHelper.getObjectValue(product, "simple");
  const slug = devHelper.getObjectValue(product, "item.slug");
  const collections = devHelper.getObjectValue(product, "item.collections", []);
  const genders = devHelper.getObjectValue(product, "item.genders", []);

  var collection = collections[0] !== undefined ? collections[0] : null;
  var gender = genders[0] !== undefined ? genders[0] : null;

  const ready = component.getData("default.ready", false);

  const [paperHovered, setHovered] = React.useState(false);

  //Add or Remove product from Wishlist
  const updateWishList = (boolean) => {
    boolean
      ? component.addToWishList(slug)
      : component.removeFromWishList(slug);

    component.setData("default.wishlist", true);
  };

  return (
    <>
      {product && (
        <TableRow
          sx={{
            borderBottom: "1px solid rgba(0,0,0,.1)",
            verticalAlign: "top",
          }}
        >
          <MyTableCell component="th" scope="row" sx={{ py: 0 }}>
            <ListItem sx={{ py: 1, px: 0, alignItems: "flex-start" }}>
              <ListItemAvatar
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                sx={{
                  position: "relative",
                  width: { xs: "150px", sm: "200px" },
                  height: { xs: "188px", sm: "250px" },
                }}
              >
                <Button
                  disableRipple
                  id="heart"
                  onClick={(e) => {
                    updateWishList(!wishlist);
                  }}
                  sx={{
                    display: {
                      xs: ready ? "block" : "none",
                      md: ready && paperHovered ? "block" : "none",
                    },
                    position: "absolute",
                    top: 5,
                    left: { xs: 10 },
                    fontSize: { xs: "16px", sm: "18px" },
                    background: "transparent",
                    minWidth: 0,
                    p: 0,
                    color: "#000",
                    "&:hover, &:focus": {
                      background: "transparent",
                    },
                  }}
                >
                  <i
                    className={
                      wishlist ? "fa-solid fa-heart" : "fa-light fa-heart"
                    }
                  ></i>
                </Button>
                {!ready && (
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="100%"
                    sx={{ position: "absolute", top: 0, left: 0 }}
                  />
                )}
                <Link
                  component={RouterLink}
                  to={
                    "/product/" +
                    devHelper.getObjectValue(product, "item.slug") +
                    "/" +
                    sku
                  }
                >
                  <CardMedia
                    component="img"
                    style={{
                      maxWidth: "100%",
                    }}
                    image={
                      component.getData(`default.img-error-product`, false)
                        ? filesUrl.concat(`/assets/product-not-found.jpg`)
                        : filesUrl.concat(`/products/${image}`)
                    }
                    onLoad={() => {
                      component.setData({ "default.ready": true });
                    }}
                    alt={image}
                    onError={() => {
                      component.setData({
                        [`default.img-error-product`]: true,
                      });
                    }}
                  ></CardMedia>
                </Link>
              </ListItemAvatar>
              <Box sx={{ flex: 1, ml: { xs: 1, sm: 3 } }}>
                {collection && (
                  <ProductCollection
                    component={RouterLink}
                    to={
                      gender
                        ? "/" +
                          devHelper.getObjectValue(
                            gender,
                            "item._values.slug"
                          ) +
                          "/collection/" +
                          devHelper.getObjectValue(
                            collection,
                            "item._values.slug"
                          )
                        : "/"
                    }
                    disableRipple
                  >
                    {devHelper.getObjectValue(collection, "item._values.name")}
                  </ProductCollection>
                )}
                <ProductName sx={{ mb: 3 }}>
                  {devHelper.getObjectValue(product, "item.name")}
                </ProductName>
                <Table>
                  <TableBody>
                    {gem && !simple && (
                      <TableRow>
                        <MyTableCell sx={{ width: { xs: "45px", sm: "60px" } }}>
                          Gem:
                        </MyTableCell>
                        <MyTableCell>
                          <Box
                            sx={{
                              width: "22px",
                              display: "inline-block",
                              margin: "0px 0px -6px",
                              borderRadius: "50px",
                            }}
                          >
                            {!component.getData(`default.ready-gem`, false) && (
                              <Skeleton
                                variant="rectangular"
                                width="100%"
                                height="100%"
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  borderRadius: "50px",
                                }}
                              />
                            )}
                            <CardMedia
                              component="img"
                              image={
                                component.getData(
                                  `default.img-error-gem`,
                                  false
                                )
                                  ? filesUrl.concat(`/assets/gem-not-found.png`)
                                  : filesUrl.concat(
                                      `/gems/${devHelper.getObjectValue(
                                        gem,
                                        "item._values.slug"
                                      )}.png`
                                    )
                              }
                              title={devHelper.getObjectValue(
                                gem,
                                "item._values.name"
                              )}
                              onLoad={() => {
                                component.setData({
                                  [`default.ready-gem`]: true,
                                });
                              }}
                              onError={() => {
                                component.setData({
                                  [`default.img-error-gem`]: true,
                                });
                              }}
                            />
                          </Box>
                        </MyTableCell>
                      </TableRow>
                    )}
                    {size && !simple && (
                      <TableRow>
                        <MyTableCell sx={{ width: { xs: "45px", sm: "60px" } }}>
                          Size:
                        </MyTableCell>
                        <MyTableCell>
                          {devHelper.getObjectValue(size, "item._values.name")}
                        </MyTableCell>
                      </TableRow>
                    )}
                    <TableRow
                      sx={{
                        display: { xs: "table-row", sm: "none" },
                      }}
                    >
                      <MyTableCell sx={{ width: { xs: "45px", sm: "60px" } }}>
                        Qty:
                      </MyTableCell>
                      <MyTableCell>
                        <Box
                          sx={{
                            display: "inline-block",
                            background: "transparent",
                            color: "#000",
                            p: 0,
                          }}
                        >
                          <Button
                            sx={{
                              backgroundColor: "transparent",
                              color: "#000",
                              height: "100%",
                              fontSize: "16px",
                              padding: 0,
                              fontWeight: 300,
                              minWidth: "initial",
                              "&:hover, &:focus": {
                                background: "transparent",
                                opacity: 0.8,
                              },
                              "&:disabled": {
                                color: "#808080",
                              },
                            }}
                            disableRipple
                            onClick={() => {
                              component.updateCart(
                                product.item,
                                "combinations.options",
                                product.sku.split(":")[1],
                                -1
                              );
                            }}
                          >
                            <i className="fa-light fa-minus"></i>
                          </Button>
                          <Typography
                            component="span"
                            sx={{
                              margin: "0px 12px",
                              fontSize: "16px",
                              fontWeight: 400,
                            }}
                          >
                            {devHelper.getObjectValue(product, "amount")}
                          </Typography>
                          <Button
                            sx={{
                              backgroundColor: "transparent",
                              color: "#000",
                              height: "100%",
                              fontSize: "16px",
                              padding: 0,
                              fontWeight: 300,
                              minWidth: "initial",
                              "&:hover, &:focus": {
                                background: "transparent",
                                opacity: 0.8,
                              },
                              "&:disabled": {
                                color: "#808080",
                              },
                            }}
                            disableRipple
                            disabled={!in_stock}
                            onClick={() => {
                              component.updateCart(
                                product.item,
                                "combinations.options",
                                product.sku.split(":")[1],
                                1
                              );
                            }}
                          >
                            <i className="fa-light fa-plus"></i>
                          </Button>
                        </Box>
                      </MyTableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        display: { xs: "table-row", sm: "none" },
                      }}
                    >
                      <MyTableCell sx={{ width: { xs: "45px", sm: "60px" } }}>
                        Price:
                      </MyTableCell>
                      <MyTableCell>
                        ${devHelper.getObjectValue(product, "price")}
                      </MyTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Link
                  sx={{
                    color: "#000",
                    fontSize: "13px",
                    fontWeight: "400",
                    cursor: "pointer",
                    borderBottom: "1px solid #000",
                    "&:hover, &:focus": {
                      borderBottom: "1px solid transparent",
                    },
                  }}
                  underline="none"
                  onClick={() => {
                    component.updateCart(
                      product.item,
                      "combinations.options",
                      product.sku.split(":")[1],
                      0
                    );
                  }}
                >
                  Remove
                </Link>
              </Box>
            </ListItem>
          </MyTableCell>
          <MyTableCell
            sx={{
              display: { xs: "none", sm: "table-cell" },
            }}
            align="center"
          >
            <Box
              sx={{
                marginTop: "30px",
                display: "inline-block",
                background: "transparent",
                color: "#000",
                padding: 0,
              }}
            >
              <Button
                sx={{
                  backgroundColor: "transparent",
                  color: "#000",
                  height: "100%",
                  fontSize: "16px",
                  padding: 0,
                  fontWeight: 300,
                  minWidth: "initial",
                  "&:hover, &:focus": {
                    background: "transparent",
                    opacity: 0.8,
                  },
                  "&:disabled": {
                    color: "#808080",
                  },
                }}
                disableRipple
                onClick={() => {
                  component.updateCart(
                    product.item,
                    "combinations.options",
                    product.sku.split(":")[1],
                    -1
                  );
                }}
              >
                <i className="fa-light fa-minus"></i>
              </Button>
              <Typography
                component="span"
                sx={{
                  margin: "0px 12px",
                  fontSize: "16px",
                  fontWeight: 400,
                }}
              >
                {devHelper.getObjectValue(product, "amount")}
              </Typography>
              <Button
                sx={{
                  backgroundColor: "transparent",
                  color: "#000",
                  height: "100%",
                  fontSize: "16px",
                  padding: 0,
                  fontWeight: 300,
                  minWidth: "initial",
                  "&:hover, &:focus": {
                    background: "transparent",
                    opacity: 0.8,
                  },
                  "&:disabled": {
                    color: "#808080",
                  },
                }}
                disableRipple
                disabled={!in_stock}
                onClick={() => {
                  component.updateCart(
                    product.item,
                    "combinations.options",
                    product.sku.split(":")[1],
                    1
                  );
                }}
              >
                <i className="fa-light fa-plus"></i>
              </Button>
            </Box>
          </MyTableCell>
          <MyTableCell
            sx={{
              display: { xs: "none", sm: "table-cell" },
            }}
            align="right"
          >
            <Box style={{ marginTop: 30 }}>
              {component
                .getApp()
                .priceFormater(
                  devHelper.getObjectValue(product, "price") *
                    devHelper.getObjectValue(product, "amount")
                )}
            </Box>
          </MyTableCell>
        </TableRow>
      )}
    </>
  );
}

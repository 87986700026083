import { Box, Link, Typography, Button, Stack, Fade } from "@mui/material";
import { useState } from "react";

import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

export default function MainBanner(props) {
  const { component, slide, padding = { xs: 16, md: 20 } } = props;
  const theme = useTheme();
  const filesUrl = component.getHelpers("env").get("files_url");
  const imgVersion = component.getHelpers("value").getValue("images-version");
  const [fade, setFade] = useState(false);

  const {
    slug,
    imagesPath,
    title,
    desc,
    descMaxWidth,
    url,
    buttons = [],
  } = slide;

  if (!slide) {
    return null;
  }

  return (
    <Box sx={{ pb: padding, minHeight: fade ? "unset" : "100vh" }}>
      <Fade in={fade} timeout={500}>
        <Box sx={{ position: "relative" }}>
          <Link component={RouterLink} to={url}>
            <picture>
              <source
                srcSet={`${filesUrl}${imagesPath}/${slug}-mobile.jpg?v=${imgVersion}&tr=w-800,h-1200,c-maintain_ratio 600w, ${filesUrl}${imagesPath}/${slug}-mobile.jpg?v=${imgVersion}&tr=w-1100,h-1344,c-maintain_ratio 900w, ${filesUrl}${imagesPath}/${slug}-desktop.jpg?v=${imgVersion}&tr=w-1920,h-743,c-maintain_ratio 1200w, ${filesUrl}${imagesPath}/${slug}-desktop.jpg?v=${imgVersion}&tr=w-1520,h-911,c-maintain_ratio 1200w, ${filesUrl}${imagesPath}/${slug}-desktop.jpg?v=${imgVersion}&tr=w-1920,h-1260,c-maintain_ratio 1500w`}
                media="(min-width: 900px)"
              />
              <img
                src={`${filesUrl}${imagesPath}/${slug}-mobile.jpg?v=${imgVersion}&tr=w-800,h-1200,c-maintain_ratio`}
                alt={title}
                loading="lazy"
                width="100vw"
                height="auto"
                style={{
                  width: "100vw",
                  height: "auto",
                  display: "block",
                }}
                onLoad={() => setFade(true)}
              />
            </picture>
          </Link>
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
            }}
          >
            <Stack
              spacing={2}
              sx={{
                px: { xs: 2, lg: 0 },
                alignItems: "center",
                mb: { xs: 6, lg: 10 },
              }}
            >
              {title && (
                <Typography
                  component="h1"
                  align="center"
                  sx={{
                    ...theme.custom.titles.title,
                    color: "#fff",
                  }}
                >
                  {title}
                </Typography>
              )}
              {desc && (
                <Typography
                  component="h2"
                  align="center"
                  sx={{
                    ...theme.custom.titles.description,
                    color: "#fff",
                    maxWidth: descMaxWidth ? descMaxWidth : "unset",
                  }}
                >
                  {desc}
                </Typography>
              )}
              {buttons.length > 0 && (
                <Stack
                  direction="row"
                  spacing={{ xs: 6, md: 8 }}
                  sx={{ alignItems: "center", justifyContent: "center" }}
                >
                  {buttons.map((button) => {
                    const { name, url } = button;
                    return (
                      <Button
                        key={name}
                        component={RouterLink}
                        to={url}
                        sx={{
                          ...theme.custom.buttons.button2,
                          color: "#fff",
                          borderBottom: "2px solid #fff",
                          "&:hover,&:focus": {
                            borderBottom: "2px solid transparent",
                          },
                        }}
                        disableRipple
                      >
                        {name}
                      </Button>
                    );
                  })}
                </Stack>
              )}
            </Stack>
          </Box>
        </Box>
      </Fade>
    </Box>
  );
}

import React from "react";
import { Box } from "@mui/material";
import NavBar from "../../../Components/Widgets/Navbar";
import MenuDrawer from "../../../Components/Widgets/MenuDrawer";
import SubMenuDrawer from "../../../Components/Widgets/SubMenuDrawer";
import Collection from "../../../Components/Widgets/Collection";
import Footer from "../widgets/footer";

const ExportDefault = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <NavBar {...props} />

      <MenuDrawer {...props} />
      <SubMenuDrawer {...props} />
      <Collection {...props} />
      <Footer {...props} />
    </Box>
  );
};

export default ExportDefault;

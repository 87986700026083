import React, { lazy, Suspense, useState, useEffect, useRef } from "react";
import { Box, CircularProgress, Fade, useMediaQuery } from "@mui/material";
import Navbar from "../widgets/navbar/navbar";
import MenuDrawer from "../../../Components/Widgets/MenuDrawer";
import SubMenuDrawer from "../../../Components/Widgets/SubMenuDrawer";
import Footer from "../widgets/footer";
import { useTheme } from "@mui/material/styles";

const MainBanner = lazy(() =>
  import("../../default/widgets/sliders/main-banner")
);

const CollectionSlider = lazy(() =>
  import("../../default/widgets/sliders/collection-slider")
);

const Grid2Section = lazy(() =>
  import("../../default/widgets/sliders/grid-2-section")
);

const ProductsListSlider = lazy(() =>
  import("../../../Components/Widgets/Sliders/ProductsListSlider")
);

const ExportDefault = (props) => {
  const [visibleSections, setVisibleSections] = useState({
    productsListSlider: false,
    grid2Section1: false,
    collectionSlider: false,
    grid2Section2: false,
  });

  const sectionRefs = useRef({
    productsListSlider: null,
    grid2Section1: null,
    collectionSlider: null,
    grid2Section2: null,
  });

  const isDesktop = useMediaQuery(useTheme().breakpoints.up("md"));

  useEffect(() => {
    const observerOptions = {
      root: null, // Use the viewport as the container
      rootMargin: "0px 0px 500px 0px", // Start loading 500px before entering the viewport
      threshold: 0.01, // Trigger when 1% of the section is visible
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const sectionId = entry.target.id;
          setVisibleSections((prev) => ({ ...prev, [sectionId]: true }));
          observer.unobserve(entry.target); // Stop observing after visibility is handled
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    // Attach the observer to each section
    Object.values(sectionRefs.current).forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect(); // Cleanup observer when component unmounts
  }, []);

  return (
    <Suspense
      fallback={
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1200,
            bgcolor: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress sx={{ color: "#000" }} size={80} thickness={1.5} />
        </Box>
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Navbar bg_color="#fff" {...props} />
        <MenuDrawer {...props} />
        <SubMenuDrawer {...props} />
        <MainBanner
          {...{
            ...props,
            ...{
              padding: 2,
              slide: {
                imagesPath: "/marketing/main",
                slug: "gifts-main-banner",
                title: "GIFT GIVING IN STLE",
                desc: " Explore our radiant selection of gifts for any occasion.",
                descMaxWidth: "640px",
                url: "/gifts",
                // buttons: [
                //   {
                //     name: "SHOP WOMEN’S",
                //     url: "/women/metal/mixed-metals",
                //   },
                //   { name: "SHOP MEN’S", url: "/men/metal/mixed-metals" },
                // ],
              },
            },
          }}
        />
        <Fade in={visibleSections.productsListSlider} timeout={0}>
          <div
            id="productsListSlider"
            ref={(el) => (sectionRefs.current.productsListSlider = el)}
          >
            <ProductsListSlider
              {...{
                ...props,
                ...{
                  params: {
                    pageAmount: 100,
                    curation: "best-sellers",
                    gender: "women,men",
                    type: "ring,bracelet,earrings,pendant,necklace",
                  },
                  amount: 15,
                },
              }}
            />
          </div>
        </Fade>
        <Fade in={visibleSections.grid2Section1} timeout={0}>
          <div
            id="grid2Section1"
            ref={(el) => (sectionRefs.current.grid2Section1 = el)}
          >
            {isDesktop ? (
              <Grid2Section
                {...{
                  ...props,
                  ...{
                    title: "SHOP BY PRICE",
                    imagesPath: "/marketing/menu/gifts",
                    slides: [
                      {
                        slug: "womens-gifts",
                        title: "WOMEN'S",
                        desc: "Explore women’s designs by price.",
                        url: "/women/gift/under-500",
                        buttons: [
                          {
                            name: "UNDER $500",
                            url: "/women/gift/under-500",
                          },
                          {
                            name: "UNDER $1000",
                            url: "/women/gift/under-1000",
                          },
                          {
                            name: "UNDER $2000",
                            url: "/women/gift/under-2000",
                          },
                          {
                            name: "Luxe Gifts",
                            url: "/women/gift/over-2000",
                          },
                        ],
                      },
                      {
                        slug: "mens-gifts",
                        title: "MEN'S",
                        desc: "Explore men’s designs by price.",
                        url: "/men/gift/under-500",
                        buttons: [
                          {
                            name: "UNDER $500",
                            url: "/men/gift/under-500",
                          },
                          {
                            name: "UNDER $1000",
                            url: "/men/gift/under-1000",
                          },
                          {
                            name: "UNDER $2000",
                            url: "/men/gift/under-2000",
                          },
                          {
                            name: "Luxe Gifts",
                            url: "/men/gift/over-2000",
                          },
                        ],
                      },
                    ],
                  },
                }}
              />
            ) : (
              <CollectionSlider
                {...{
                  ...props,
                  ...{
                    title: "SHOP BY PRICE",
                    imagesPath: "/marketing/menu/gifts",
                    slides: [
                      {
                        slug: "womens-gifts",
                        title: "WOMEN'S",
                        desc: "Explore women’s designs by price.",
                        url: "/women/gift/under-500",
                        buttons: [
                          {
                            name: "UNDER $500",
                            url: "/women/gift/under-500",
                          },
                          {
                            name: "UNDER $1000",
                            url: "/women/gift/under-1000",
                          },
                          {
                            name: "UNDER $2000",
                            url: "/women/gift/under-2000",
                          },
                          {
                            name: "Luxe Gifts",
                            url: "/women/gift/over-2000",
                          },
                        ],
                      },
                      {
                        slug: "mens-gifts",
                        title: "MEN'S",
                        desc: "Explore men’s designs by price.",
                        url: "/men/gift/under-500",
                        buttons: [
                          {
                            name: "UNDER $500",
                            url: "/men/gift/under-500",
                          },
                          {
                            name: "UNDER $1000",
                            url: "/men/gift/under-1000",
                          },
                          {
                            name: "UNDER $2000",
                            url: "/men/gift/under-2000",
                          },
                          {
                            name: "Luxe Gifts",
                            url: "/men/gift/over-2000",
                          },
                        ],
                      },
                    ],
                  },
                }}
              />
            )}
          </div>
        </Fade>
        <Fade in={visibleSections.collectionSlider} timeout={0}>
          <div
            id="collectionSlider"
            ref={(el) => (sectionRefs.current.collectionSlider = el)}
          >
            <CollectionSlider
              {...{
                ...props,
                ...{
                  title: "SHOP BY FEATURE",
                  imagesPath: "/marketing/menu/portrait",
                  slides: [
                    {
                      slug: "women-best-sellers",
                      title: "BEST SELLERS",
                      desc: "Explore our top-selling designs that feature timeless qualities.",
                      url: "/women/curation/best-sellers",
                      buttons: [
                        {
                          name: "WOMEN'S",
                          url: "/women/curation/best-sellers",
                        },
                        {
                          name: "MEN'S",
                          url: "/men/curation/best-sellers",
                        },
                      ],
                    },
                    {
                      slug: "women-new-arrivals",
                      title: "NEW IN",
                      desc: "Shop new and refreshing designs from our latest collections.",
                      url: "/women/curation/new-arrivals",
                      buttons: [
                        {
                          name: "WOMEN'S",
                          url: "/women/curation/new-arrivals",
                        },
                        {
                          name: "MEN'S",
                          url: "/men/curation/new-arrivals",
                        },
                      ],
                    },
                    {
                      slug: "women-seasonal-edit",
                      title: "SEASONAL EDIT",
                      desc: "Explore stylish designs that reflect the seasonal palette.",
                      url: "/women/curation/seasonal-edit",
                      buttons: [
                        {
                          name: "WOMEN'S",
                          url: "/women/curation/seasonal-edit",
                        },
                        {
                          name: "MEN'S",
                          url: "/men/curation/seasonal-edit",
                        },
                      ],
                    },
                    {
                      slug: "women-novelties",
                      title: "NOVELTIES",
                      desc: "View exquisite statement novelty designs selected from each collection.",
                      url: "/women/curation/novelties",
                      buttons: [
                        {
                          name: "WOMEN'S",
                          url: "/women/curation/novelties",
                        },
                        {
                          name: "MEN'S",
                          url: "/men/curation/novelties",
                        },
                      ],
                    },
                  ],
                },
              }}
            />
          </div>
        </Fade>
        <Fade in={visibleSections.grid2Section2} timeout={0}>
          <div
            id="grid2Section2"
            ref={(el) => (sectionRefs.current.grid2Section2 = el)}
          >
            {isDesktop ? (
              <Grid2Section
                {...{
                  ...props,
                  ...{
                    title: "SHOP BY MATERIAL",
                    imagesPath: "/marketing/menu/gifts",
                    slides: [
                      {
                        slug: "womens-material",
                        title: "WOMEN'S",
                        desc: "Explore women’s designs by material.",
                        url: "/women/metal/18kt-gold",
                        buttons: [
                          {
                            name: "GOLD",
                            url: "/women/metal/18kt-gold",
                          },
                          {
                            name: "MIXED METALS",
                            url: "/women/metal/mixed-metals",
                          },
                          {
                            name: "STERLING SILVER",
                            url: "/women/metal/sterling-silver",
                          },
                          {
                            name: "LEATHER",
                            url: "/women/metal/leather",
                          },
                        ],
                      },
                      {
                        slug: "mens-material",
                        title: "MEN'S",
                        desc: "Explore men’s designs by material.",
                        url: "/men/metal/18kt-gold",
                        buttons: [
                          {
                            name: "GOLD",
                            url: "/men/metal/18kt-gold",
                          },
                          {
                            name: "MIXED METALS",
                            url: "/men/metal/mixed-metals",
                          },
                          {
                            name: "STERLING SILVER",
                            url: "/men/metal/sterling-silver",
                          },
                          {
                            name: "LEATHER",
                            url: "/men/metal/leather",
                          },
                        ],
                      },
                    ],
                  },
                }}
              />
            ) : (
              <CollectionSlider
                {...{
                  ...props,
                  ...{
                    title: "SHOP BY MATERIAL",
                    imagesPath: "/marketing/menu/gifts",
                    slides: [
                      {
                        slug: "womens-material",
                        title: "WOMEN'S",
                        desc: "Explore women’s designs by material.",
                        url: "/women/metal/18kt-gold",
                        buttons: [
                          {
                            name: "GOLD",
                            url: "/women/metal/18kt-gold",
                          },
                          {
                            name: "MIXED METALS",
                            url: "/women/metal/mixed-metals",
                          },
                          {
                            name: "STERLING SILVER",
                            url: "/women/metal/sterling-silver",
                          },
                          {
                            name: "LEATHER",
                            url: "/women/metal/leather",
                          },
                        ],
                      },
                      {
                        slug: "mens-material",
                        title: "MEN'S",
                        desc: "Explore men’s designs by material.",
                        url: "/men/metal/18kt-gold",
                        buttons: [
                          {
                            name: "GOLD",
                            url: "/men/metal/18kt-gold",
                          },
                          {
                            name: "MIXED METALS",
                            url: "/men/metal/mixed-metals",
                          },
                          {
                            name: "STERLING SILVER",
                            url: "/men/metal/sterling-silver",
                          },
                          {
                            name: "LEATHER",
                            url: "/men/metal/leather",
                          },
                        ],
                      },
                    ],
                  },
                }}
              />
            )}
          </div>
        </Fade>
        <Footer {...props} />
      </Box>
    </Suspense>
  );
};

export default ExportDefault;

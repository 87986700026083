import React, { useState } from "react";
import { AppBar, useScrollTrigger, Slide, Box } from "@mui/material";
import MainAppbar from "../../../../Components/Widgets/MainAppbar";
import CartPopper from "../../../../Components/Widgets/CartPopper";
import { useTheme } from "@mui/material/styles";

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children ?? <div />}
    </Slide>
  );
}

export default function NavBar(props) {
  const [openMenu, setOpenMenu] = useState(false);
  const theme = useTheme();

  return (
    <>
      <HideOnScroll {...props}>
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            background: "transparent",
          }}
          elevation={0}
        >
          <MainAppbar
            {...{
              ...props,
              openMenu,
              setOpenMenu,
            }}
          />
          <CartPopper
            {...{
              ...props,
              ...{},
            }}
          />
        </AppBar>
      </HideOnScroll>
      <Box
        sx={{
          height: {
            xs: theme.appbar_height.mobile,
            md: theme.appbar_height.desktop,
          },
        }}
      />
      {openMenu && (
        <Box
          sx={{
            position: "fixed",
            left: 0,
            zIndex: 1000,
            width: "100%",
            height: "100%",
            backdropFilter: "blur(3px)",
          }}
        />
      )}
    </>
  );
}

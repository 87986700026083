import { Box } from "@mui/material";

export default function ImageBackground(props) {
  const { component, slide } = props;

  const filesUrl = component.getHelpers("env").get("files_url");
  const imgVersion = component.getHelpers("value").getValue("images-version");

  const { slug, title, imagesPath } = slide || {}; // Fallback to empty object if slide is undefined

  if (!slide) {
    return null;
  }

  return (
    <>
      <picture>
        <source
          srcSet={`${filesUrl}${imagesPath}/${slug}-mobile.jpg?v=${imgVersion}&tr=w-800,h-1200,c-maintain_ratio 600w, ${filesUrl}${imagesPath}/${slug}-mobile.jpg?v=${imgVersion}&tr=w-1100,h-1344,c-maintain_ratio 900w, ${filesUrl}${imagesPath}/${slug}-desktop.jpg?v=${imgVersion}&tr=w-1920,h-743,c-maintain_ratio 1200w, ${filesUrl}${imagesPath}/${slug}-desktop.jpg?v=${imgVersion}&tr=w-1520,h-911,c-maintain_ratio 1200w, ${filesUrl}${imagesPath}/${slug}-desktop.jpg?v=${imgVersion}&tr=w-1920,h-1260,c-maintain_ratio 1500w`}
          media="(min-width: 900px)"
        />
        <img
          src={`${filesUrl}${imagesPath}/${slug}-mobile.jpg?v=${imgVersion}&tr=w-800,h-1200,c-maintain_ratio`}
          alt={title}
          loading="lazy"
          sizes="100vw"
          style={{
            width: "100vw",
            height: "100vh",
            objectFit: "cover",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: -2,
          }}
        />
      </picture>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: -1,
          bgcolor: "#9e9f93",
          opacity: 0.5,
        }}
      />
    </>
  );
}

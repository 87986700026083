import React from "react";
import { Box } from "@mui/material";
import NavBar from "../../../../Components/Widgets/Navbar";
import MenuDrawer from "../../../../Components/Widgets/MenuDrawer";
import SubMenuDrawer from "../../../../Components/Widgets/SubMenuDrawer";
import Footer from "../../widgets/footer";
import Legacy from "../../widgets/legacy/legacy";

const ExportDefault = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <NavBar {...props} />
      <MenuDrawer {...props} />
      <SubMenuDrawer {...props} />
      <Legacy {...props} />
      <Footer {...props} />
    </Box>
  );
};

export default ExportDefault;

import Home from "./Page/Home";
import Gifts from "./Page/Gifts";

////
import ContactUs from "./Page/ContactUs";
import Login from "./Page/Login";
import Register from "./Page/Register";
import Collection from "./Page/Collection";
import Product from "./Page/Product";
import Wishlist from "./Page/Wishlist";
import Cart from "./Page/Cart";
import Results from "./Page/Results";
import Checkout from "./Page/Checkout";
import OrderComplete from "./Page/OrderComplete";
import Men from "./Page/Men";
import Women from "./Page/Women";
import CollectionsWomen from "./Page/CollectionsWomen";
import CollectionsMen from "./Page/CollectionsMen";
import Locations from "./Page/Locations";
import TrunkShows from "./Page/TrunkShows";
import Boutiques from "./Page/Boutiques";
import Assistance from "./Page/Assistance";

// World Section
import About from "./Page/World/About";
import Legacy from "./Page/World/Legacy";
import Limelight from "./Page/World/Limelight";
import Press from "./Page/World/Press";
import Films from "./Page/World/Films";

// User Section
import Profile from "./Page/User/Profile";
import Addresses from "./Page/User/Addresses";
import Orders from "./Page/User/Orders";
import Order from "./Page/User/Order";
import Coupons from "./Page/User/Coupons";

// Redirect Section
import Redirect from "./Page/Redirect";

const Module = {
  Home,
  Gifts,
  Men,
  Women,
  CollectionsWomen,
  CollectionsMen,
  //
  Locations,
  TrunkShows,
  Boutiques,
  ContactUs,
  //
  Assistance,
  Login,
  Register,
  //
  Collection,
  Product,
  //
  Wishlist,
  Cart,
  Results,
  //
  Checkout,
  OrderComplete,
  // World Section
  About,
  Legacy,
  Limelight,
  Press,
  Films,
  // User Section
  Profile,
  Addresses,
  Orders,
  Order,
  Coupons,
  // Redirect Section
  Redirect,
};

export default Module;

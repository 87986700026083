import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  CardMedia,
  Button,
  Link,
  Typography,
  Box,
  Skeleton,
  Fade,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";

const ImageBtn = styled(Button)(({ theme }) => ({
  position: "absolute",
  background: "transparent",
  zIndex: "1",
  padding: 0,
  minWidth: 0,
  color: "#000",
  textTransform: "none",
  fontWeight: 400,
  "&:disabled": {
    pointerEvents: "auto",
  },
  "&:hover, &:focus": {
    background: "transparent",
  },
}));

export default function ProductSliderView(props) {
  const { component } = props;
  const theme = useTheme();

  const [paperHovered, setHovered] = useState(false);
  const [ready, setReady] = useState(false);

  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const imgVersion = component.getHelpers("value").getValue("images-version");

  const product = component.getData("default.product", null);

  const [activeImage, setActiveImage] = useState(0);
  const [showModel, setShowModel] = useState(false);

  const images = component.getData("default.images", []);

  var model_img = null;
  if (images.length) {
    var i = images.findIndex((img) => img.name.includes("model".toLowerCase()));
    if (i !== -1) {
      model_img = images[i].name;
    } else if (images[1] !== undefined) {
      model_img = images[1].name;
    }
  }

  const gems = devHelper.getObjectValue(product, "_values.gems", []);
  const slug = devHelper.getObjectValue(product, "_values.slug");
  const wishlist = component.getData("default.wishlist", false);
  const collections = devHelper.getObjectValue(
    product,
    "_values.collections",
    []
  );
  const genders = devHelper.getObjectValue(product, "_values.genders", []);

  var collection = collections[0] !== undefined ? collections[0] : null;
  var gender = genders[0] !== undefined ? genders[0] : null;

  //Add or Remove product from Wishlist
  const updateWishList = (boolean) => {
    boolean
      ? component.addToWishList(slug)
      : component.removeFromWishList(slug);

    component.setData("default.wishlist", true);
  };

  //Change product
  const changeProduct = (gem, number) => {
    setActiveImage(0);

    product.active_gem = number;
    var has_size = gem.item._values.sizes.length;
    var values = has_size
      ? gem.item._values.sizes[0]
      : gem.item._values.default;

    component.setProductValues(product, values, has_size);
  };

  //Change slider image
  const handleImageChange = (step) => {
    setActiveImage(step);
  };

  const handleNext = () => {
    setActiveImage((prevActiveImage) => prevActiveImage + 1);
  };

  const handleBack = () => {
    setActiveImage((prevActiveImage) => prevActiveImage - 1);
  };

  return (
    <Box
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => {
        setHovered(false);
        setActiveImage(0);
      }}
    >
      {product && (
        <Box>
          <Box
            sx={{
              position: "relative",
              width: "100%",
            }}
          >
            <Fade in={ready} timeout={500}>
              <Typography
                disabled
                sx={{
                  display: devHelper.getObjectValue(product, "curation")
                    ? "block"
                    : "none",
                  position: "absolute",
                  top: 10,
                  left: 10,
                  fontSize: { xs: 12, sm: 14 },
                  bgcolor: "#fff",
                  zIndex: 1,
                  p: 1,
                  lineHeight: 1,
                  borderRadius: 0,
                  color: "#000",
                }}
              >
                {devHelper.getObjectValue(
                  product,
                  "curation.item._values.name"
                )}
              </Typography>
            </Fade>
            <Fade in={ready} timeout={500}>
              <Box
                sx={{
                  display: {
                    xs: "block",
                    md: paperHovered ? "block" : "none",
                  },
                }}
              >
                <ImageBtn
                  disableRipple
                  id="heart"
                  onClick={(e) => {
                    updateWishList(!wishlist);
                  }}
                  sx={{
                    top: 17,
                    right: 10,
                    fontSize: { xs: "16px", sm: "18px" },
                  }}
                  aria-label="Add to Wishlist"
                >
                  <i
                    className={
                      wishlist ? "fa-solid fa-heart" : "fa-light fa-heart"
                    }
                  ></i>
                </ImageBtn>
                <ImageBtn
                  id="left-arrow"
                  onClick={() => handleBack()}
                  sx={{
                    color: "#000",
                    top: "50%",
                    transform: "translateY(-50%)",
                    left: { xs: 10, sm: 15 },
                    fontSize: "20px",
                    display: {
                      xs: "none",
                      sm: images.length !== 0 ? "block" : "none",
                    },
                  }}
                  disabled={activeImage === 0}
                  disableRipple
                >
                  <i className="fa-light fa-chevron-left" />
                </ImageBtn>
                <ImageBtn
                  id="right-arrow"
                  onClick={() => handleNext()}
                  sx={{
                    color: "#000",
                    top: "50%",
                    transform: "translateY(-50%)",
                    right: { xs: 10, sm: 15 },
                    fontSize: "20px",
                    display: {
                      xs: "none",
                      sm: images.length !== 0 ? "block" : "none",
                    },
                  }}
                  disabled={activeImage === images.length - 1}
                  disableRipple
                >
                  <i className="fa-light fa-chevron-right" />
                </ImageBtn>
              </Box>
            </Fade>
            <Fade in={!ready} timeout={500}>
              <Skeleton
                variant="rectangular"
                width="100%"
                height="100%"
                sx={{ position: "absolute", top: 0, left: 0 }}
              />
            </Fade>
            <Link
              component={RouterLink}
              to={
                "/product/" +
                devHelper.getObjectValue(product, "_values.slug") +
                "/" +
                devHelper.getObjectValue(product, "_values.sku")
              }
              sx={{
                height: "100%",
              }}
            >
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeImage}
                onChangeIndex={handleImageChange}
                enableMouseEvents
                style={{
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                <Fade in={ready && images.length !== 0}>
                  <CardMedia
                    sx={{ maxWidth: "100%" }}
                    component="img"
                    image={
                      component.getData(`default.img-error-product-0`, false)
                        ? `${filesUrl}/assets/product-not-found.jpg?v=${imgVersion}&tr=w-720,h-900,c-maintain_ratio`
                        : showModel
                        ? `${filesUrl}/products/${model_img}?v=${imgVersion}&tr=w-720,h-900,c-maintain_ratio`
                        : `${filesUrl}/products/${devHelper.getObjectValue(
                            images[0],
                            "name"
                          )}?v=${imgVersion}&tr=w-720,h-900,c-maintain_ratio`
                    }
                    alt={`${devHelper.getObjectValue(
                      product,
                      "_values.name"
                    )} image 0`}
                    onLoad={() => {
                      setReady(true);
                    }}
                    onError={() => {
                      component.setData({
                        [`default.img-error-product-0`]: true,
                      });
                    }}
                    onMouseEnter={() =>
                      model_img !== null && setShowModel(true)
                    }
                    onMouseLeave={() => setShowModel(false)}
                  />
                </Fade>
                {ready && images.length !== 0 ? (
                  images.slice(1).map((image, index) => (
                    <Fade
                      in={component.getData(
                        `default.img-ready-product-${index + 1}`,
                        false
                      )}
                      key={`product-img-${index + 1}`}
                    >
                      <CardMedia
                        sx={{ maxWidth: "100%" }}
                        component="img"
                        lazy="loading"
                        image={
                          component.getData(
                            `default.img-error-product-${index + 1}`,
                            false
                          )
                            ? `${filesUrl}/assets/product-not-found.jpg?v=${imgVersion}&tr=w-720,h-900,c-maintain_ratio`
                            : showModel
                            ? `${filesUrl}/products/${model_img}?v=${imgVersion}&tr=w-720,h-900,c-maintain_ratio`
                            : `${filesUrl}/products/${devHelper.getObjectValue(
                                image,
                                "name"
                              )}?v=${imgVersion}&tr=w-720,h-900,c-maintain_ratio`
                        }
                        alt={`${devHelper.getObjectValue(
                          product,
                          "_values.name"
                        )} image ${index + 1}`}
                        onLoad={() => {
                          component.setData({
                            [`default.img-ready-product-${index + 1}`]: true,
                          });
                        }}
                        onError={() => {
                          component.setData({
                            [`default.img-error-product-${index + 1}`]: true,
                          });
                        }}
                        onMouseEnter={() =>
                          model_img !== null && setShowModel(true)
                        }
                        onMouseLeave={() => setShowModel(false)}
                      />
                    </Fade>
                  ))
                ) : (
                  <CardMedia
                    style={{
                      maxWidth: "100%",
                    }}
                    component="img"
                    image={`${filesUrl}/assets/product-not-found.jpg?v=${imgVersion}&tr=w-720,h-900,c-maintain_ratio`}
                    alt="product not found"
                    onLoad={() => {
                      component.setData({ "default.ready": true });
                    }}
                  />
                )}
              </SwipeableViews>
            </Link>
          </Box>
          <Fade in={ready} timeout={1000}>
            <Box sx={{ textAlign: "center" }}>
              {collection && (
                <Button
                  sx={{
                    fontFamily: "Spartan-SemiBold",
                    fontSize: { xs: 11, md: 12 },
                    padding: 0,
                    "&:hover, &:focus": {
                      background: "transparent",
                    },
                  }}
                  fullWidth
                  component={RouterLink}
                  to={
                    gender
                      ? "/" +
                        devHelper.getObjectValue(gender, "item._values.slug") +
                        "/collection/" +
                        devHelper.getObjectValue(
                          collection,
                          "item._values.slug"
                        )
                      : "/"
                  }
                  disableRipple
                >
                  {devHelper.getObjectValue(collection, "item._values.name")}
                </Button>
              )}
              <Button
                sx={{
                  fontFamily: "Poppins-Regular",
                  fontSize: { xs: 14, md: 17 },
                  textTransform: "none",
                  padding: "0px",
                  mt: { xs: "-5px", sm: "-12px" },
                  lineHeight: { xs: "17px", sm: "unset" },
                  "&:hover, &:focus": {
                    background: "transparent",
                    color: "#000",
                  },
                }}
                fullWidth
                component={RouterLink}
                to={
                  "/product/" +
                  slug +
                  +"/" +
                  devHelper.getObjectValue(product, "_values.sku")
                }
              >
                {devHelper.getObjectValue(product, "_values.name")}
              </Button>
              <Typography
                sx={{
                  fontFamily: "Spartan-SemiBold",
                  fontSize: { xs: 11, md: 12 },
                  mt: { xs: "0px", sm: "-3px" },
                }}
              >
                {devHelper.getObjectValue(product, "active_gem") === -1 &&
                devHelper.getObjectValue(product, "maxPrice") !==
                  devHelper.getObjectValue(product, "_values.price")
                  ? `${component
                      .getApp()
                      .priceFormater(
                        devHelper.getObjectValue(product, "_values.price")
                      )}-${component
                      .getApp()
                      .priceFormater(
                        devHelper.getObjectValue(product, "maxPrice")
                      )}`
                  : component
                      .getApp()
                      .priceFormater(
                        devHelper.getObjectValue(product, "_values.price")
                      )}
              </Typography>
              <Box>
                {!product.simple &&
                  gems.map((gem, index) => (
                    <Box
                      key={"gem-" + slug + "-" + index}
                      sx={{
                        position: "relative",
                        cursor: "pointer",
                        width: "35px",
                        height: "35px",
                        display: "inline-block",
                        margin: "0px 3px",
                        borderRadius: "50px",
                        padding: "3px",
                        border:
                          devHelper.getObjectValue(product, "active_gem") ===
                          index
                            ? "1px solid #000"
                            : "1px solid transparent",
                      }}
                    >
                      {!component.getData(
                        `default.ready-${slug}-gem-${index}`,
                        false
                      ) && (
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height="100%"
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            borderRadius: "50px",
                          }}
                        />
                      )}
                      <CardMedia
                        component="img"
                        image={
                          component.getData(
                            `default.img-error-${slug}-gem-${index}`,
                            false
                          )
                            ? `${filesUrl}/assets/gem-not-found.png?v=${imgVersion}&tr=w-40,h-40,c-maintain_ratio`
                            : `${filesUrl}/gems/${devHelper.getObjectValue(
                                gem,
                                "item._values.slug"
                              )}.png?v=${imgVersion}&tr=w-40,h-40,c-maintain_ratio`
                        }
                        title={devHelper.getObjectValue(
                          gem,
                          "item._values.name"
                        )}
                        onClick={() => {
                          changeProduct(gem, index);
                        }}
                        onLoad={() => {
                          component.setData({
                            [`default.ready-${slug}-gem-${index}`]: true,
                          });
                        }}
                        onError={() => {
                          component.setData({
                            [`default.img-error-${slug}-gem-${index}`]: true,
                          });
                        }}
                      />
                    </Box>
                  ))}
              </Box>
            </Box>
          </Fade>
        </Box>
      )}
    </Box>
  );
}

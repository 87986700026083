import React from "react";
import {
  Typography,
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  Button,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CardMedia,
  Slider,
  MenuItem,
  TextField,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Filters(props) {
  const { component, filterDrawer } = props;
  const devHelper = component.getHelpers("dev");
  const filters = component.getData("default.filters", []);
  const filesUrl = component.getHelpers("env").get("files_url");

  return (
    <>
      {filterDrawer && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            paddingTop: "20px",
            height: "100%",
            left: 0,
            overflowY: "auto",
            transition: "visibility 0.3s",
            visibility: filterDrawer ? "visible" : "hidden",
            px: filterDrawer ? 2 : 0,
            width: filterDrawer ? "350px" : 0,
            "@media (max-width: 500px)": {
              width: filterDrawer ? "100%" : 0,
              margin: 0,
            },
          }}
        >
          <Box
            sx={{
              marginBottom: "20px",
            }}
          >
            <FormControl fullWidth>
              <TextField
                select
                value={component
                  .getData("default.activeFilters.sortBy", [])
                  .join("-")}
                label="Sort By"
                onChange={(e) => component.sortBy(e.target.value)}
                SelectProps={{
                  MenuProps: { disablePortal: true },
                }}
              >
                <MenuItem value={"price-asc"}>Price ASC</MenuItem>
                <MenuItem value={"price-desc"}>Price DESC</MenuItem>
              </TextField>
            </FormControl>
          </Box>
          {filters.map((filter) => {
            const { type } = filter;
            const name = type === "price_range" ? "price" : type;

            return (
              <Accordion
                sx={{
                  backgroundColor: "transparent",
                  border: "unset",
                  boxShadow: "unset",
                  "&::before": { opacity: "1!important" },
                }}
                key={`filter-${type}`}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{component.ucfirst(name)}</Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    "&.MuiAccordionDetails-root": {
                      padding: "0px 8px",
                    },
                  }}
                >
                  {type !== "price" ? (
                    <FormControl
                      component="fieldset"
                      s={{ marginLeft: "16px" }}
                    >
                      <FormGroup>
                        {component
                          .getData(`default.${type}s`, [])
                          .map((item, i) => {
                            const filterAmount = component.getFilterAmount(
                              type,
                              item
                            );

                            return (
                              filterAmount > 0 && (
                                <FormControlLabel
                                  key={devHelper.getObjectValue(
                                    item,
                                    "_values.slug"
                                  )}
                                  disabled={
                                    devHelper.getObjectValue(
                                      item,
                                      "_values.total"
                                    ) === 0
                                  }
                                  id={
                                    devHelper.getObjectValue(
                                      item,
                                      "_values.slug"
                                    ) +
                                    "-" +
                                    i
                                  }
                                  control={
                                    <Checkbox
                                      onChange={(event) =>
                                        component.toggleFilter(type, item)
                                      }
                                      className="checkbox"
                                      checked={component.isFilterSelected(
                                        type,
                                        item
                                      )}
                                    />
                                  }
                                  sx={{
                                    fontSize: "15px",
                                    "& .MuiTypography-root": {
                                      display: "contents!important",
                                    },
                                  }}
                                  label={
                                    type === "gem" ? (
                                      <>
                                        <CardMedia
                                          component="img"
                                          image={filesUrl.concat(
                                            `/gems/${devHelper.getObjectValue(
                                              item,
                                              "_values.slug"
                                            )}.png`
                                          )}
                                          title={devHelper.getObjectValue(
                                            item,
                                            "_values.name"
                                          )}
                                          onError={(e) => {
                                            e.target.src = filesUrl.concat(
                                              `/assets/gem-not-found.png`
                                            );
                                          }}
                                          sx={{
                                            cursor: "pointer",
                                            maxWidth: "42px",
                                            borderRadius: 50,
                                            padding: "10px",
                                            maxHeight: "42px",
                                            display: "inline",
                                          }}
                                        />
                                        <Box
                                          component="span"
                                          sx={{ textTransform: "uppercase" }}
                                        >
                                          {devHelper.getObjectValue(
                                            item,
                                            "_values.name"
                                          ) +
                                            " (" +
                                            filterAmount +
                                            ")"}
                                        </Box>
                                      </>
                                    ) : (
                                      <Box
                                        component="span"
                                        sx={{
                                          textTransform: "uppercase",
                                        }}
                                      >
                                        {devHelper.getObjectValue(
                                          item,
                                          "_values.name"
                                        ) +
                                          " (" +
                                          filterAmount +
                                          ")"}
                                      </Box>
                                    )
                                  }
                                />
                              )
                            );
                          })}
                      </FormGroup>
                    </FormControl>
                  ) : (
                    <Slider
                      value={component.getPriceRange(filter)}
                      size="small"
                      sx={{ margin: "25px 5% 0px", maxWidth: "90%" }}
                      onChangeCommitted={(e, value) =>
                        component.changePriceRange(value, true)
                      }
                      onChange={(e, value) => component.changePriceRange(value)}
                      valueLabelDisplay="on"
                      aria-labelledby="range-slider"
                      min={component.getMinPrice(filter)}
                      max={component.getMaxPrice(filter)}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })}
          <Stack
            direction="row"
            sx={{ mt: 2, justifyContent: "space-between" }}
          >
            <Button
              sx={{
                bgcolor: "#fff",
                border: "1px solid #000",
                borderRadius: 0,
                color: "#000",
                px: 2,
                "&:hover": { bgcolor: "#fff" },
              }}
              onClick={() => {
                props.setFilterDrawer(false);
                component.clearFiltersAndFetch();
              }}
              id="clear_filters"
              size="small"
            >
              {component.ucfirst("clear Filters")}
            </Button>
            <Button
              sx={{
                bgcolor: "#000",
                border: "1px solid #000",
                borderRadius: 0,
                color: "#fff",
                px: 2,
                "&:hover": { bgcolor: "#000" },
              }}
              onClick={() => props.setFilterDrawer(false)}
              size="small"
            >
              {component.ucfirst("Show Results")}
            </Button>
          </Stack>
        </Box>
      )}
    </>
  );
}

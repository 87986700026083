import React, { lazy, Suspense, useState, useEffect, useRef } from "react";
import { Box, CircularProgress, Fade } from "@mui/material";
import Navbar from "../widgets/navbar/navbar";
import MenuDrawer from "../../../Components/Widgets/MenuDrawer";
import SubMenuDrawer from "../../../Components/Widgets/SubMenuDrawer";
import Footer from "../widgets/footer";

const MainBanner = lazy(() =>
  import("../../default/widgets/sliders/main-banner")
);

const CollectionSlider = lazy(() =>
  import("../../default/widgets/sliders/collection-slider")
);

const AllCollections = lazy(() =>
  import("../../default/widgets/sliders/all-collections")
);

const ExportDefault = (props) => {
  const [visibleSections, setVisibleSections] = useState({
    collectionSlider: false,
    allCollections: false,
  });

  const sectionRefs = useRef({
    collectionSlider: null,
    allCollections: null,
  });

  useEffect(() => {
    const observerOptions = {
      root: null, // Use the viewport as the container
      rootMargin: "0px 0px 500px 0px", // Start loading 500px before entering the viewport
      threshold: 0.01, // Trigger when 1% of the section is visible
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const sectionId = entry.target.id;
          setVisibleSections((prev) => ({ ...prev, [sectionId]: true }));
          observer.unobserve(entry.target); // Stop observing after visibility is handled
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    // Attach the observer to each section
    Object.values(sectionRefs.current).forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect(); // Cleanup observer when component unmounts
  }, []);

  return (
    <Suspense
      fallback={
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1200,
            bgcolor: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress sx={{ color: "#000" }} size={80} thickness={1.5} />
        </Box>
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Navbar bg_color="#fff" {...props} />
        <MenuDrawer {...props} />
        <SubMenuDrawer {...props} />
        <MainBanner
          {...{
            ...props,
            ...{
              slide: {
                imagesPath: "/marketing/main",
                slug: "women-collections-main-banner",
                title: "Women’s Gold Creations.",
                desc: "View a bejeweled assortment of 18k gold designs inspired by the influence of ancient Greece to exude luxurious refinement.",
                // descMaxWidth: "640px",
                url: "/women/metal/18kt-gold",

                buttons: [
                  {
                    name: "EXPLORE ALL",
                    url: "/women/metal/18kt-gold",
                  },
                ],
              },
            },
          }}
        />
        <Fade in={visibleSections.collectionSlider} timeout={0}>
          <div
            id="collectionSlider"
            ref={(el) => (sectionRefs.current.collectionSlider = el)}
          >
            <CollectionSlider
              {...{
                ...props,
                ...{
                  title: "WOMEN’S RECENT COLLECTIONS",
                  imagesPath: "/marketing/collections",
                  slides: [
                    {
                      slug: "karia",
                      title: "KARIA COLLECTION",
                      desc: "Discover Karia’s designs with chiseled details, flowing forms, and majestic stones.",
                      url: "/women/collection/karia",
                      buttons: [
                        {
                          name: "SHOP KARIA",
                          url: "/women/collection/karia",
                        },
                      ],
                    },
                    {
                      slug: "kyma",
                      title: "KYMA COLLECTION",
                      desc: "Discover designs meticulously crafted to capture the Aegean Sea’s waves with clean lines, smooth curves, and sculptural forms.",
                      url: "/women/collection/kyma",
                      buttons: [
                        {
                          name: "SHOP KYMA",
                          url: "/women/collection/kyma",
                        },
                      ],
                    },
                    {
                      slug: "gen-k",
                      title: "GEN-K COLLECTION",
                      desc: "Discover our revitalized Gen-K collection, featuring new vibrant colors and reimagined designs.",
                      url: "/women/collection/gen-k",
                      buttons: [
                        {
                          name: "SHOP GEN-K",
                          url: "/women/collection/gen-k",
                        },
                      ],
                    },
                    {
                      slug: "zodiac",
                      title: "ZODIAC COLLECTION",
                      desc: "Choose from 12 enchanting zodiac characters, each intricately designed to reveal the unique essence of every sign.",
                      url: "/women/collection/zodiac",
                      buttons: [
                        {
                          name: "SHOP ZODIAC",
                          url: "/women/collection/zodiac",
                        },
                      ],
                    },
                  ],
                },
              }}
            />
          </div>
        </Fade>
        <Fade in={visibleSections.allCollections} timeout={0}>
          <div
            id="allCollections"
            ref={(el) => (sectionRefs.current.allCollections = el)}
          >
            <AllCollections
              {...{
                ...props,
                ...{
                  gender: "women",
                },
              }}
            />
          </div>
        </Fade>
        <Footer {...props} />
      </Box>
    </Suspense>
  );
};

export default ExportDefault;

import React, { useEffect } from "react";
import {
  Container,
  Box,
  Grid,
  Button,
  FormControl,
  Select,
  MenuItem,
  Typography,
  useMediaQuery,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Filters from "../filters";
import ProductListView from "../../../../Components/Widgets/ProductListView";
import ProductListSkeletonView from "../../widgets/product_list_skeleton_view";

export default function Collection(props) {
  const { component } = props;
  const theme = useTheme();
  const types = component.getData("default.types", []);

  const { products = null, filters = [] } = component.getData("default", {});
  const term = component.getSearchTerm();
  const devHelper = component.getHelpers("dev");

  const view = useMediaQuery(theme.breakpoints.up("md")) ? "desktop" : "mobile";

  const [filterDrawer, setFilterDrawer] = React.useState(false);
  const [activeType, setActiveType] = React.useState("all");
  const pageGender = component.getGender();
  const pageType = component.getData("default.pageType", "");
  const pageValue = component.getData("default.pageValue", "");
  const page = `${pageGender}-${pageType}-${pageValue}`;
  const title = `${page}-title_${view}`;
  const subtitle = `${page}-subtitle_${view}`;

  useEffect(() => {
    const onScroll = () => {
      const max = 0.6;

      if (
        !component.getData("default.loading") &&
        component.canFetchMore() &&
        (window.scrollY + window.innerHeight) / document.body.clientHeight > max
      ) {
        component.fetchMore();
      }

      localStorage.setItem("scrollPosition", window.scrollY);
      localStorage.setItem("scrollList", window.location.pathname);
    };

    const onBack = () => {
      localStorage.removeItem("saveScrollPosition");
      const pathname = window.location.pathname.split("/");

      pathname.shift();

      if (pathname.length) {
        const type = pathname.shift();

        if (["women", "men"].indexOf(type) !== -1) {
          component.getPage().redirect(`/${type}`);
        }
      }
    };

    window.addEventListener("scroll", onScroll);
    window.addEventListener("popstate", onBack);

    return () => {
      if (onScroll) {
        window.removeEventListener("scroll", onScroll);
      }

      if (onBack) {
        window.removeEventListener("popstate", onBack);
      }

      const scrollY = localStorage.getItem("scrollPosition");

      localStorage.setItem("saveScrollPosition", scrollY);
    };
  }, [component]);

  return (
    <Container disableGutters maxWidth="xl" sx={{ mt: 3 }}>
      <Stack
        spacing={1.5}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          my: 1,
          px: 2,
          mb: 2.5,
        }}
      >
        {term && (
          <Typography
            component="h1"
            align="center"
            sx={{
              ...theme.custom.titles.title,
            }}
          >
            Search Results For: «{decodeURIComponent(term)}»
          </Typography>
        )}
        {!component.trans(title).includes(`_${view}`) && (
          <Typography
            component="h1"
            align="center"
            sx={{
              ...theme.custom.titles.title,
            }}
          >
            {component.trans(title)}
          </Typography>
        )}
        {!component.trans(subtitle).includes(`_${view}`) && (
          <Typography
            component="h3"
            align="center"
            sx={{
              ...theme.custom.titles.desc,
            }}
          >
            {component.trans(subtitle)}
          </Typography>
        )}
        <Box sx={{ height: { xs: 40, md: 28 } }}>
          {component.showTypes() &&
            types.length !== 0 &&
            products &&
            products.length !== 0 && (
              <>
                <Stack
                  direction="row"
                  spacing={3}
                  sx={{
                    display: { xs: "none", md: "flex" },
                    justifyContent: "center",
                  }}
                >
                  <Button
                    sx={{
                      fontSize: 15,
                      fontFamily: "Spartan-SemiBold",
                      borderBottom: "1px solid transparent",
                      p: 0,
                      minWidth: 0,
                      borderRadius: 0,
                      "&:hover,&:focus": {
                        backgroundColor: "transparent",
                      },
                    }}
                    disableRipple
                    onClick={() => {
                      setFilterDrawer(false);
                      component.allTypes();
                    }}
                  >
                    ALL
                  </Button>
                  {types
                    .filter((type) => component.typeIsVisible(type))
                    .map((type) => {
                      const { _values = {} } = type;
                      const { slug, name } = _values;
                      return (
                        <Button
                          sx={{
                            fontSize: 15,
                            fontFamily: "Spartan-SemiBold",
                            borderBottom: component.isOnType(slug)
                              ? "1px solid #000"
                              : "1px solid transparent",
                            p: 0,
                            minWidth: 0,
                            borderRadius: 0,
                            "&:hover,&:focus": {
                              backgroundColor: "transparent",
                            },
                          }}
                          disableRipple
                          key={`type-${slug}`}
                          onClick={() => {
                            setFilterDrawer(false);
                            component.selectType(slug);
                          }}
                        >
                          {component.ucfirst(name)}
                        </Button>
                      );
                    })}
                </Stack>
                <FormControl
                  fullWidth
                  sx={{
                    display: { xs: "initial", md: "none" },
                  }}
                  size="small"
                >
                  <Select
                    onChange={(e) => {
                      const value = e.target.value;
                      setActiveType(value);
                      setFilterDrawer(false);
                      value === "all"
                        ? component.allTypes()
                        : component.selectType(e.target.value);
                    }}
                    value={activeType}
                    sx={{ minWidth: 140 }}
                  >
                    <MenuItem value="all">{component.ucfirst("all")}</MenuItem>
                    {types
                      .filter((type) => component.typeIsVisible(type))
                      .map((type) => {
                        const { _values = {} } = type;
                        const { slug, name } = _values;
                        return (
                          <MenuItem key={`type-${slug}`} value={slug}>
                            {component.ucfirst(name)}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </>
            )}
        </Box>
      </Stack>
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          px: 1,
          mb: 1,
        }}
      >
        <Button
          sx={{
            borderRadius: 0,
            fontSize: 16,
            fontFamily: "Spartan-Medium",
            textTransform: "none",
            p: 0,
            "&:hover": {
              background: "transparent",
            },
            "& i": {
              fontSize: 20,
              mr: 1,
            },
          }}
          onClick={() => setFilterDrawer((prev) => !prev)}
          disableRipple
        >
          <i className="fa-light fa-sliders" />
          Filters &amp; Sort
        </Button>
        <Typography
          sx={{
            borderBottom: "1px solid #000",
            fontSize: 15,
            fontFamily: "Spartan-Medium",
          }}
        >
          {`${component.getData("default.total", 0)} products`}
        </Typography>
      </Stack>
      <Box
        sx={{
          position: "relative",
          overflowX: "hidden",
          minHeight: 600,
        }}
      >
        <Filters
          {...{
            ...props,
            ...{
              filterDrawer,
              filters,
              setFilterDrawer: (boolean) => setFilterDrawer(boolean),
            },
          }}
        />
        <Box
          sx={{
            width: "100%",
            transition: "margin-left 0.3s",
            marginLeft: filterDrawer ? "350px" : 0,
            "@media (max-width: 500px)": {
              marginLeft: filterDrawer ? "100%" : 0,
            },
          }}
        >
          <Grid
            container
            sx={{
              alignItems: "flex-start",
            }}
            justifycontent="center"
            direction="row"
            spacing={0.5}
          >
            {products === null ? (
              [...new Array(40)].map((product, index) => (
                <ProductListSkeletonView
                  key={`skeleton-${index}`}
                  {...{
                    ...props,
                    ...{
                      grid: 4,
                    },
                  }}
                />
              ))
            ) : products.length > 0 ? (
              products.map((product) => (
                <Grid
                  item
                  md={4}
                  sm={6}
                  xs={6}
                  justifycontent="center"
                  key={`${devHelper.getObjectValue(product, "_id")}`}
                >
                  <ProductListView
                    {...{
                      ...props,
                      ...{
                        product,
                      },
                    }}
                  />
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Typography
                  align="center"
                  sx={{
                    my: 12,
                    fontFamily: "Spartan-SemiBold",
                    fontSize: 16,
                  }}
                >
                  NO PRODUCTS FOUND
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          my: 6,
          position: "relative",
        }}
      >
        {component.canFetchMore() && (
          <>
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
                zIndex: -1,
                position: "absolute",
                top: "50%",
                left: 0,
                px: { xs: 2, sm: 4, md: 6, lg: 8 },
              }}
            >
              <Box
                sx={{
                  borderTop: "2px solid #808080",
                  width: "100%",
                }}
              ></Box>
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
}

import React from "react";
import { Box } from "@mui/material";
import NavBar from "../../../../Components/Widgets/Navbar";
import MenuDrawer from "../../../../Components/Widgets/MenuDrawer";
import SubMenuDrawer from "../../../../Components/Widgets/SubMenuDrawer";
import MainBanner from "../../widgets/sliders/main-banner";
import Footer from "../../widgets/footer";
import About from "../../widgets/about/about";

const ExportDefault = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <NavBar {...props} />
      <MenuDrawer {...props} />
      <SubMenuDrawer {...props} />
      <MainBanner
        {...{
          ...props,
          ...{
            slide: {
              imagesPath: "/marketing/main",
              slug: "about-banner",
              url: "/about",
            },
          },
        }}
      />
      <About {...props} />
      <Footer {...props} />
    </Box>
  );
};

export default ExportDefault;

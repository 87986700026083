import React, { lazy, Suspense, useState, useEffect, useRef } from "react";
import { Box, CircularProgress, Fade } from "@mui/material";
import Navbar from "../widgets/navbar/navbar";
import MenuDrawer from "../../../Components/Widgets/MenuDrawer";
import SubMenuDrawer from "../../../Components/Widgets/SubMenuDrawer";
import Footer from "../widgets/footer";

const Product = lazy(() => import("../../../Components/Widgets/Product"));

// const fadeTime = 500;

const ExportDefault = (props) => {
  const [visibleSections, setVisibleSections] = useState({
    product: false,
    footer: false,
  });

  const sectionRefs = useRef({
    product: null,
    footer: null,
  });

  useEffect(() => {
    const observerOptions = {
      root: null, // Use the viewport as the container
      rootMargin: "0px 0px 200px 0px", // Start loading 200px before entering the viewport
      threshold: 0.01, // Trigger when 1% of the section is visible
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const sectionId = entry.target.id;
          setVisibleSections((prev) => ({ ...prev, [sectionId]: true }));
          observer.unobserve(entry.target); // Stop observing after visibility is handled
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    // Attach the observer to each section
    Object.values(sectionRefs.current).forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect(); // Cleanup observer when component unmounts
  }, []);

  return (
    <Suspense
      fallback={
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1200,
            bgcolor: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress sx={{ color: "#000" }} size={80} thickness={1.5} />
        </Box>
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Navbar bg_color="#fff" {...props} />
        <MenuDrawer {...props} />
        <SubMenuDrawer {...props} />
        <Fade in={true} timeout={0}>
          <div id="product" ref={(el) => (sectionRefs.current.product = el)}>
            <Product {...props} />
          </div>
        </Fade>
        <Fade in={visibleSections.footer} timeout={0}>
          <div id="footer" ref={(el) => (sectionRefs.current.footer = el)}>
            <Footer {...props} />
          </div>
        </Fade>
      </Box>
    </Suspense>
  );
};

export default ExportDefault;

import React from "react";
import { styled } from "@mui/material/styles";
import {
  Container,
  Typography,
  Box,
  Button,
  Stack,
  Paper,
  TextField,
} from "@mui/material";
import ImageBackround from "../sliders/image-backround";

const MyTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#000",
    borderRadius: 0,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#000",
    borderRadius: 0,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
    "&:hover fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
    "&.Mui-focused fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
  },
}));

export default function ContactUs(props) {
  const { component } = props;

  return (
    <Container
      sx={{
        px: 4,
        minHeight: "100vh",
      }}
      disableGutters
      maxWidth="xl"
    >
      <ImageBackround
        {...{
          ...props,
          ...{
            slide: {
              title: "contact-us",
              slug: "locations-background",
              imagesPath: "/marketing/background",
            },
          },
        }}
      />
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: -1,
          bgcolor: "#9e9f93",
          opacity: 0.5,
        }}
      />
      <Stack sx={{ alignItems: "center", my: 14 }} spacing={4}>
        <Typography
          variant="h1"
          sx={{
            fontSize: 26,
            fontFamily: "Poppins-Bold",
            color: "#fff",
          }}
        >
          CONTACT US
        </Typography>
        <Box maxWidth="sm">
          <form onSubmit={(e) => component.sendRequest(e)}>
            <Paper sx={{ p: 3, borderRadius: 0 }}>
              <MyTextField
                variant="outlined"
                margin="normal"
                fullWidth
                label={component.ucfirst("name-textfield")}
                name="name"
                helperText={component.getData("error.fields.name")}
                value={component.getData("default.fields.name", "")}
                onChange={(e) => {
                  component.setData({
                    "default.fields.name": e.target.value,
                  });
                  component.deleteData("error.fields.name");
                }}
                error={component.dataExists("error.fields.name")}
                sx={{ mt: 0 }}
              />
              <MyTextField
                variant="outlined"
                margin="normal"
                fullWidth
                label={component.ucfirst("email-textfield")}
                name="email"
                helperText={component.getData("error.fields.email")}
                value={component.getData("default.fields.email", "")}
                onChange={(e) => {
                  component.setData({
                    "default.fields.email": e.target.value,
                  });
                  component.deleteData("error.fields.email");
                }}
                error={component.dataExists("error.fields.email")}
              />
              <MyTextField
                variant="outlined"
                margin="normal"
                fullWidth
                label={component.ucfirst("phone-textfield") + " ( Optional )"}
                name="phone"
                helperText={component.getData("error.fields.phone")}
                value={component.getData("default.fields.phone", "")}
                onChange={(e) => {
                  component.setData({
                    "default.fields.phone": e.target.value,
                  });
                  component.deleteData("error.fields.phone");
                }}
                error={component.dataExists("error.fields.phone")}
              />
              <MyTextField
                variant="outlined"
                margin="normal"
                fullWidth
                label={component.ucfirst("message-textfield")}
                name="message"
                helperText={component.getData("error.fields.message")}
                value={component.getData("default.fields.message", "")}
                onChange={(e) => {
                  component.setData({
                    "default.fields.message": e.target.value,
                  });
                  component.deleteData("error.fields.message");
                }}
                error={component.dataExists("error.fields.message")}
                minRows={3}
                multiline
              />
              <Button
                type="submit"
                fullWidth
                sx={{
                  height: 56,
                  mt: 2,
                  background: "#000",
                  borderRadius: 0,
                  border: "1px solid #000",
                  color: "#ffffff",
                  "&:hover": {
                    background: "#000",
                  },
                }}
              >
                {component.ucfirst("submit")}
              </Button>
            </Paper>
          </form>
        </Box>
      </Stack>
    </Container>
  );
}

import React from "react";
import { styled } from "@mui/material/styles";
import {
  SwipeableDrawer,
  Link,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Box,
  Button,
  Stack,
  IconButton,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  marginBottom: "40px",
  "&:not(:last-child)": {
    borderBottom: 0,
    m: 0,
  },
  "&:before": {
    display: "none",
  },
  "& .MuiButtonBase-root": { padding: 0 },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <i
        className="fa-regular fa-chevron-down"
        style={{ fontSize: "18px", color: "#000" }}
      ></i>
    }
    {...props}
  />
))(({ theme }) => ({
  border: "none",
  p: 0,
  fontFamily: "Spartan-SemiBold",
  fontSize: "14px",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(-90deg)",
    border: "none",
    p: 0,
  },
  "& .MuiAccordionSummary-content": {
    border: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "0 16px",
}));

const MyLink = styled(Link)(({ theme }) => ({
  color: "#000",
  padding: "0px",
  backgroundColor: "transparent",
  borderRadius: "0px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: 14,
  fontFamily: "Spartan-Bold",
  marginBottom: "40px",
}));

export default function MenuDrawer(props) {
  const { component } = props;

  const isAuth = component.isAuthenticated();
  const mainMenu = component.getData("default.mainMenu", []);
  const filesUrl = component.getHelpers("env").get("files_url");
  const imgVersion = component.getHelpers("value").getValue("images-version");

  return (
    <SwipeableDrawer
      anchor="left"
      open={component.getData("default.opened", false)}
      onClose={() => component.toggle()}
      onOpen={() => {}}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 2,
        "& .MuiDrawer-paper": {
          p: 0,
          overflowY: "scroll",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          width: { xs: "100%", sm: "500px" },
          flexDirection: "column",
          justifyContent: "space-between",
        },
        "& .MuiDrawer-paper::-webkit-scrollbar": {
          display: "none",
        },
      }}
      transitionDuration={{ enter: 500, exit: 500 }}
      swipeAreaWidth={0}
    >
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          bgcolor: "#fff",
          px: 2,
          width: "100%",
        }}
      >
        <Button
          sx={{
            outline: "none",
            p: 0,
            "&:hover": {
              bgcolor: "transparent",
            },
            img: { width: { xs: 75, md: 95 } },
          }}
          disableRipple
          component={RouterLink}
          to="/"
          onClick={() => component.toggle()}
        >
          <img
            src={`${filesUrl}/logos/black/square.png?v=${imgVersion}&tr=w-350`}
            srcSet={`${filesUrl}/logos/black/square.png?v=${imgVersion}&tr=w-300 600w, ${filesUrl}/logos/black/square.png?v=${imgVersion}&tr=w-350 900w`}
            sizes="(max-width: 600px) 300px, (max-width: 900px) 350px"
            alt="Konstantino Home"
            title="Konstantino Home"
          />
        </Button>
        <IconButton
          onClick={() => component.toggle()}
          aria-label="close dialog"
          color="primary"
        >
          <i className="fa-regular fa-xmark" />
        </IconButton>
      </Stack>
      <Box sx={{ p: 3, mt: 2, flexGrow: 1 }}>
        <Box>
          {mainMenu.map((menu) => {
            const { slug, name } = menu;

            return (
              <Box key={`mobile-main-menu-root-${slug}`}>
                <MyLink
                  underline="none"
                  onClick={() => {
                    component.toggleSubMenuDrawer(menu);
                  }}
                  sx={{
                    "& i": {
                      fontSize: 20,
                    },
                  }}
                >
                  {name}
                  {/* <i className="fa-regular fa-chevron-right" /> */}
                  <i className="fa-regular fa-arrow-right" />
                </MyLink>
              </Box>
            );
          })}

          <Box sx={{ borderBottom: "1px solid #000", mb: 5 }}></Box>

          {isAuth ? (
            <Accordion elevation={0} disableGutters>
              <AccordionSummary>My Account</AccordionSummary>
              <AccordionDetails>
                <MyLink
                  underline="none"
                  component={RouterLink}
                  to="profile"
                  onClick={() => component.toggle()}
                >
                  {component.ucfirst("k-profile")}
                </MyLink>
                <MyLink
                  underline="none"
                  component={RouterLink}
                  to="/addresses"
                  onClick={() => component.toggle()}
                >
                  {component.ucfirst("k-addresses")}
                </MyLink>
                <MyLink
                  underline="none"
                  component={RouterLink}
                  to="/orders"
                  onClick={() => component.toggle()}
                >
                  {component.ucfirst("k-orders")}
                </MyLink>
                <MyLink
                  underline="none"
                  component={RouterLink}
                  to="/coupons"
                  onClick={() => component.toggle()}
                >
                  {component.ucfirst("k-coupons")}
                </MyLink>
              </AccordionDetails>
            </Accordion>
          ) : (
            <MyLink
              underline="none"
              component={RouterLink}
              to="/signin"
              onClick={() => component.toggle()}
            >
              My Account
            </MyLink>
          )}

          <MyLink
            underline="none"
            component={RouterLink}
            to="/wishlist"
            onClick={() => component.toggle()}
          >
            My Wishlist
          </MyLink>

          {isAuth ? (
            <MyLink
              underline="none"
              onClick={() => component.getPage().logout()}
            >
              {component.ucfirst("logout")}
            </MyLink>
          ) : (
            <MyLink
              underline="none"
              component={RouterLink}
              to="/signin"
              onClick={() => component.toggle()}
            >
              Sign In
            </MyLink>
          )}
        </Box>
      </Box>
      <Stack
        direction="row"
        spacing={{ xs: 2, sm: 3 }}
        sx={{
          bgcolor: "#9e9f93",
          py: 2,
          justifyContent: "center",
          "& a": {
            color: "#fff",
            fontSize: 13,
            borderRadius: 0,
            fontWeight: 600,
            lineHeight: 1,
            p: 0,
            "&:hover,&:focus": {
              backgroundColor: "transparent",
              borderBottom: "2px solid #fff",
            },
          },
        }}
      >
        <Button to="/contact-us" component={RouterLink} disableRipple>
          CONTACT US
        </Button>
        <Button to="/boutiques" component={RouterLink} disableRipple>
          STORE LOCATOR
        </Button>
        <Button to="/trunk-shows" component={RouterLink} disableRipple>
          ANNOUCMENTS
        </Button>
      </Stack>
    </SwipeableDrawer>
  );
}

import React from "react";
import { Container, Typography, Grid, Box } from "@mui/material";
import ProductListView from "../../../../Components/Widgets/ProductListView";
import ProductListSkeletonView from "../../widgets/product_list_skeleton_view";

export default function Wishlist(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const products = component.getData("default.products", null);

  return (
    <Container
      sx={{
        pb: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "600px",
      }}
      disableGutters
      maxWidth="xl"
    >
      <Box sx={{ textAlign: "center", py: 3 }}>
        <Typography
          sx={{
            fontFamily: "Poppins-Regular",
            fontSize: "34px",
          }}
        >
          {component.ucfirst("wishlist")}
        </Typography>
      </Box>
      <Grid
        container
        sx={{
          alignItems: "flex-start",
        }}
        justifycontent="center"
        direction="row"
        spacing={0.5}
      >
        {products === null ? (
          [...new Array(40)].map((product, index) => (
            <ProductListSkeletonView
              key={`skeleton-${index}`}
              {...{
                ...props,
                ...{
                  grid: 4,
                },
              }}
            />
          ))
        ) : products.length > 0 ? (
          products.map((product) => (
            <Grid
              item
              md={4}
              sm={6}
              xs={6}
              justifycontent="center"
              key={`${devHelper.getObjectValue(product, "_id")}`}
            >
              <ProductListView
                {...{
                  ...props,
                  ...{
                    product,
                  },
                }}
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography
              align="center"
              sx={{
                my: 12,
                fontFamily: "Spartan-SemiBold",
                fontSize: 16,
              }}
            >
              NO PRODUCTS FOUND
            </Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

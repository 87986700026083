import React from "react";
import { isMobile } from "react-device-detect";
import {
  Paper,
  Box,
  Fade,
  List,
  Button,
  Container,
  CircularProgress,
  Grid,
  ClickAwayListener,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ProductCartPopperView from "../../../../Components/Widgets/ProductCartPopperView";

export default function NavBar(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");

  const order = component.getData("default.order", null);
  const open = component.getData("default.opened", false);
  // const open = true;
  const popper_min_height = "500px";

  return (
    <>
      {open && !isMobile && (
        <Container
          maxWidth="xl"
          disableGutters
          sx={{ display: "flex", position: "relative" }}
        >
          <ClickAwayListener onClickAway={() => component.setPopperOpen(false)}>
            <Box
              sx={{ position: "absolute", right: 0, zIndex: 3000 }}
              onMouseEnter={() => component.setPopperOpen(true)}
              onMouseLeave={() => component.setPopperOpen(false)}
            >
              <Fade
                in={open}
                timeout={{ enter: 500, exit: 500, float: "right" }}
              >
                <Paper
                  sx={{
                    position: "relative",
                    overflowY: "auto",
                    width: "450px",
                    px: "20px",
                  }}
                  elevation={3}
                  square
                >
                  {!order ? (
                    <Grid
                      container
                      direction="row-reverse"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ height: popper_min_height }}
                    >
                      <Grid item>
                        <CircularProgress
                          sx={{
                            color: "#000",
                          }}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      {order.items && order.items.length !== 0 ? (
                        <>
                          <List
                            sx={{
                              width: "100%",
                              maxHeight: "425px",
                              padding: 0,
                              position: "relative",
                              overflowX: "hidden",
                            }}
                          >
                            {order.items.map((product, index) => (
                              <ProductCartPopperView
                                key={
                                  devHelper.getObjectValue(product, "sku") +
                                  "-" +
                                  devHelper.getObjectValue(product, "amount")
                                }
                                {...{
                                  ...props,
                                  ...{
                                    product,
                                  },
                                }}
                              />
                            ))}
                          </List>
                          <Box style={{ textAlign: "center" }}>
                            <Button
                              component={RouterLink}
                              to="/cart"
                              sx={{
                                background: "#ffffff",
                                borderBottom: "1px solid #000",
                                borderRadius: 0,
                                color: "#000",
                                height: "30px",
                                width: "100px",
                                margin: "15px 0px 20px",
                                fontWeight: 400,
                                "&:hover, &:focus": {
                                  borderBottom: "unset",
                                  background: "transparent",
                                },
                              }}
                              disableRipple
                            >
                              view bag
                            </Button>
                            <Button
                              component={RouterLink}
                              to="/checkout"
                              fullWidth
                              sx={{
                                background: "#000",
                                border: "1px solid #000",
                                borderRadius: 0,
                                color: "#ffffff",
                                // width: "100%",
                                padding: "15px auto",
                                fontWeight: 400,
                                height: "40px",
                                marginBottom: "20px",
                                "&:hover, &:focus": {
                                  background: "#ffffff",
                                  color: "#000",
                                },
                              }}
                              disableRipple
                            >
                              Checkout
                            </Button>
                          </Box>
                        </>
                      ) : (
                        <Grid
                          container
                          direction="row-reverse"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ height: popper_min_height }}
                        >
                          <Grid item>
                            THERE ARE NO ITEMS IN YOUR SHOPPING BAG
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}
                </Paper>
              </Fade>
            </Box>
          </ClickAwayListener>
        </Container>
      )}
    </>
  );
}

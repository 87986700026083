import React from "react";
import { styled } from "@mui/material/styles";
import {
  ListItem,
  ListItemAvatar,
  CardMedia,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Box,
  Skeleton,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const MyTableRow = styled(TableRow)({
  marginBottom: "5px",
});

const MyTableCell = styled(TableCell)({
  padding: "0px 0px 5px",
  borderBottom: "none",
  fontFamily: "Spartan-Medium",
});

const ProductName = styled(Typography)({
  fontFamily: "Poppins-Regular",
  fontSize: "18px",
});

const ProductCollection = styled(Button)({
  fontFamily: "Spartan-SemiBold",
  fontSize: "12px",
  padding: "0px",
  minWidth: 0,
  "&:hover, &:focus": {
    background: "transparent",
  },
});

export default function NavBar(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");

  const product = component.getData("default.product", null);
  const image = component.getData("default.image", null);

  const gem = devHelper.getObjectValue(product, "gem");
  const size = devHelper.getObjectValue(product, "size");
  const simple = devHelper.getObjectValue(product, "simple");
  const collections = devHelper.getObjectValue(product, "item.collections", []);
  const genders = devHelper.getObjectValue(product, "item.genders", []);

  var collection = collections[0] !== undefined ? collections[0] : null;
  var gender = genders[0] !== undefined ? genders[0] : null;
  return (
    <>
      {product && (
        <ListItem
          sx={{
            height: "180px",
            padding: "5px",
            borderBottom: "1px solid rgba(0,0,0,.1)",
            alignItems: "flex-start",
          }}
        >
          <ListItemAvatar
            sx={{
              position: "relative",
              height: "170px",
              width: "136px",
            }}
          >
            {!component.getData(`default.ready-product`, false) && (
              <Skeleton
                variant="rectangular"
                width="100%"
                height="100%"
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />
            )}
            <CardMedia
              component="img"
              image={
                component.getData(`default.img-error-product`, false)
                  ? filesUrl.concat(`/assets/product-not-found.jpg`)
                  : filesUrl.concat(`/products/${image}`)
              }
              alt={image}
              style={{
                maxWidth: "100%",
                height: "100%",
              }}
              onLoad={() => {
                component.setData({
                  [`default.ready-product`]: true,
                });
              }}
              onError={() => {
                component.setData({
                  [`default.img-error-product`]: true,
                });
              }}
            />
          </ListItemAvatar>
          <Box sx={{ width: "100%", px: 2 }}>
            <Box sx={{ textAlign: "center", mb: 2 }}>
              {collection && (
                <ProductCollection
                  fullWidth
                  component={RouterLink}
                  to={
                    gender
                      ? "/" +
                        devHelper.getObjectValue(gender, "item._values.slug") +
                        "/collection/" +
                        devHelper.getObjectValue(
                          collection,
                          "item._values.slug"
                        )
                      : "/"
                  }
                  disableRipple
                >
                  {devHelper.getObjectValue(collection, "item._values.name")}
                </ProductCollection>
              )}
              <ProductName>
                {devHelper.getObjectValue(product, "item.name")}
              </ProductName>
            </Box>
            <Table>
              <TableBody>
                {gem && !simple && (
                  <MyTableRow>
                    <MyTableCell component="th" scope="row">
                      Gem:
                    </MyTableCell>
                    <MyTableCell>
                      <Box
                        sx={{
                          width: "20px",
                          display: "inline-block",
                          margin: "0px 0px -5px",
                          borderRadius: "50px",
                          "@media (max-width: 450px )": {
                            width: "25px",
                            margin: "0px 1px 0px",
                            padding: "1px",
                          },
                        }}
                      >
                        {!component.getData(`default.ready-gem`, false) && (
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height="100%"
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              borderRadius: "50px",
                            }}
                          />
                        )}
                        <CardMedia
                          component="img"
                          image={
                            component.getData(`default.img-error-gem`, false)
                              ? filesUrl.concat(`/assets/gem-not-found.png`)
                              : filesUrl.concat(
                                  `/gems/${devHelper.getObjectValue(
                                    gem,
                                    "item._values.slug"
                                  )}.png`
                                )
                          }
                          title={devHelper.getObjectValue(
                            gem,
                            "item._values.name"
                          )}
                          onLoad={() => {
                            component.setData({
                              [`default.ready-gem`]: true,
                            });
                          }}
                          onError={() => {
                            component.setData({
                              [`default.img-error-gem`]: true,
                            });
                          }}
                        />
                      </Box>
                    </MyTableCell>
                  </MyTableRow>
                )}
                {size && !simple && (
                  <MyTableRow>
                    <MyTableCell component="th" scope="row">
                      Size:
                    </MyTableCell>
                    <MyTableCell>
                      {devHelper.getObjectValue(size, "item._values.name")}
                    </MyTableCell>
                  </MyTableRow>
                )}
                <MyTableRow>
                  <MyTableCell component="th" scope="row">
                    Qty:
                  </MyTableCell>
                  <MyTableCell>
                    {devHelper.getObjectValue(product, "amount")}
                  </MyTableCell>
                </MyTableRow>
                <MyTableRow>
                  <MyTableCell component="th" scope="row">
                    Price:
                  </MyTableCell>
                  <MyTableCell>
                    {component
                      .getApp()
                      .priceFormater(
                        devHelper.getObjectValue(product, "price") *
                          devHelper.getObjectValue(product, "amount")
                      )}
                  </MyTableCell>
                </MyTableRow>
              </TableBody>
            </Table>
          </Box>
        </ListItem>
      )}
    </>
  );
}

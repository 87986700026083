import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/drawers/menu-drawer";
import Services from "../../Services/Services";

export default class MenuDrawer extends Component {
  id = "menu-drawer";
  group = "wishlist";

  mainMenu = [
    {
      slug: "new-in",
      name: "NEW IN",
      url: "/women/curation/new-arrivals",
      subMenu: {
        curation: [
          {
            slug: "womens-new-arrivals",
            title: "NEW WOMEN’S",
            button: "EXPLORE ALL",
            url: "/women/curation/new-arrivals",
          },
          {
            slug: "mens-new-arrivals",
            title: "NEW MEN’S",
            button: "EXPLORE ALL",
            url: "/men/curation/new-arrivals",
          },
        ],
      },
    },
    {
      slug: "womens",
      name: "WOMEN'S",
      url: "/women",
      subMenu: {
        category: [
          {
            slug: "women-rings",
            title: "Rings",
            url: "/women/type/ring",
          },
          {
            slug: "women-bracelets",
            title: "Bracelets",
            url: "/women/type/bracelet",
          },
          {
            slug: "women-pendants",
            title: "Pendants & Charms",
            url: "/women/type/pendant",
          },
          {
            slug: "women-earrings",
            title: "Earrings",
            url: "/women/type/earrings",
          },
          {
            slug: "women-necklaces",
            title: "Necklaces",
            url: "/women/type/necklace",
          },
          {
            slug: "women-all_",
            title: "All",
            url: "/women/types/all",
          },
        ],
        material: [
          {
            slug: "women-gold",
            title: "Gold",
            url: "/women/metal/18kt-gold",
          },
          {
            slug: "women-mixed-metals",
            title: "Mixed Metals",
            url: "/women/metal/mixed-metals",
          },
          {
            slug: "women-sterling-silver",
            title: "Sterling Silver",
            url: "/women/metal/sterling-silver",
          },
          {
            slug: "women-leather",
            title: "Leather",
            url: "/women/metal/leather",
          },
        ],
        featured: [
          {
            slug: "women-best-sellers",
            title: "Best Sellers",
            url: "/women/curation/best-sellers",
          },
          {
            slug: "women-new-arrivals",
            title: "New In",
            url: "/women/curation/new-arrivals",
          },
          {
            slug: "women-crosses",
            title: "Crosses",
            url: "/women/curation/cross",
          },
          {
            slug: "women-seasonal-edit",
            title: "Seasonal Edit",
            url: "/women/curation/seasonal-edit",
          },
          {
            slug: "women-novelties",
            title: "Novelties",
            url: "/women/curation/novelties",
          },
          {
            slug: "birthstones",
            title: "Birth Stones",
            url: "/women/collection/birthstone",
          },
          {
            slug: "zodiacs",
            title: "Zodiacs",
            url: "/women/collection/zodiac",
          },
        ],
        collections: [
          {
            slug: "view-all_",
            title: "View All",
            url: "/women/collections",
          },
        ],
      },
    },
    {
      slug: "mens",
      name: "MEN'S",
      url: "/men",
      subMenu: {
        category: [
          {
            slug: "men-rings",
            title: "Rings",
            url: "/men/type/ring",
          },
          {
            slug: "men-bracelets",
            title: "Bracelets",
            url: "/men/type/bracelet",
          },
          {
            slug: "men-pendants",
            title: "Pendants & Talismans",
            url: "/men/type/pendant",
          },
          {
            slug: "men-necklaces",
            title: "Necklaces & Chains",
            url: "/men/type/necklace",
          },
          {
            slug: "men-cufflinks",
            title: "Cufflinks",
            url: "/men/type/cufflinks",
          },
          {
            slug: "men-all_",
            title: "All",
            url: "/men/types/all",
          },
        ],
        material: [
          {
            slug: "men-gold",
            title: "Gold",
            url: "/men/metal/18kt-gold",
          },
          {
            slug: "men-mixed-metals",
            title: "Mixed Metals",
            url: "/men/metal/mixed-metals",
          },
          {
            slug: "men-sterling-silver",
            title: "Sterling Silver",
            url: "/men/metal/sterling-silver",
          },
          {
            slug: "men-leather",
            title: "Leather",
            url: "/men/metal/leather",
          },
        ],
        featured: [
          {
            slug: "men-best-sellers",
            title: "Best Sellers",
            url: "/men/curation/best-sellers",
          },
          {
            slug: "men-new-in",
            title: "New In",
            url: "/men/curation/new-arrivals",
          },
          {
            slug: "men-crosses",
            title: "Crosses",
            url: "/men/curation/cross",
          },
          {
            slug: "men-seasonal-edit",
            title: "Seasonal Edit",
            url: "/men/curation/seasonal-edit",
          },
          {
            slug: "birthstones",
            title: "Birth Stones",
            url: "/women/collection/birthstone",
          },
          {
            slug: "zodiacs",
            title: "Zodiacs",
            url: "/women/collection/zodiac",
          },
        ],
        collections: [
          {
            slug: "view-all_",
            title: "View All",
            url: "/men/collections",
          },
        ],
      },
    },
    {
      slug: "gifts",
      name: "GIFTS",
      url: "/gifts",
      menu_template: "gifts",
      womensMenu: {
        price: [
          {
            slug: "gifts-under-500",
            title: "Under $500",
            url: "/women/gift/under-500",
          },
          {
            slug: "gifts-under-1000",
            title: "Under $1000",
            url: "/women/gift/under-1000",
          },
          {
            slug: "gifts-under-2000",
            title: "Under $2000",
            url: "/women/gift/under-2000",
          },
          {
            slug: "luxe-gifts",
            title: "Luxe Gifts",
            url: "/women/gift/over-2000",
          },
        ],
        featured: [
          {
            slug: "women-best-sellers",
            title: "Best Sellers",
            url: "/women/curation/best-sellers",
          },
          {
            slug: "women-new-arrivals",
            title: "New In",
            url: "/women/curation/new-arrivals",
          },
          {
            slug: "women-crosses",
            title: "Crosses",
            url: "/women/curation/cross",
          },
          {
            slug: "women-seasonal-edit",
            title: "Seasonal Edit",
            url: "/women/curation/seasonal-edit",
          },
          {
            slug: "women-novelties",
            title: "Novelties",
            url: "/women/curation/novelties",
          },
          {
            slug: "birthstones",
            title: "Birth Stones",
            url: "/women/collection/birthstone",
          },
          {
            slug: "zodiacs",
            title: "Zodiacs",
            url: "/women/collection/zodiac",
          },
        ],
        material: [
          {
            slug: "women-gold",
            title: "Gold",
            url: "/women/metal/18kt-gold",
          },
          {
            slug: "women-mixed-metals",
            title: "Mixed Metals",
            url: "/women/metal/mixed-metals",
          },
          {
            slug: "women-sterling-silver",
            title: "Sterling Silver",
            url: "/women/metal/sterling-silver",
          },
          {
            slug: "women-leather",
            title: "Leather",
            url: "/women/metal/leather",
          },
        ],
      },
      mensMenu: {
        price: [
          {
            slug: "gifts-under-500",
            title: "Under $500",
            url: "/men/gift/under-500",
          },
          {
            slug: "gifts-under-1000",
            title: "Under $1000",
            url: "/men/gift/under-1000",
          },
          {
            slug: "gifts-under-2000",
            title: "Under $2000",
            url: "/men/gift/under-2000",
          },
          {
            slug: "luxe-gifts",
            title: "Luxe Gifts",
            url: "/men/gift/over-2000",
          },
        ],
        featured: [
          {
            slug: "men-best-sellers",
            title: "Best Sellers",
            url: "/men/curation/best-sellers",
          },
          {
            slug: "men-new-in",
            title: "New In",
            url: "/men/curation/new-arrivals",
          },
          {
            slug: "men-crosses",
            title: "Crosses",
            url: "/men/curation/cross",
          },
          {
            slug: "men-seasonal-edit",
            title: "Seasonal Edit",
            url: "/men/curation/seasonal-edit",
          },
          {
            slug: "men-novelties",
            title: "Novelties",
            url: "/men/curation/novelties",
          },
          {
            slug: "birthstones",
            title: "Birth Stones",
            url: "/women/collection/birthstone",
          },
          {
            slug: "zodiacs",
            title: "Zodiacs",
            url: "/women/collection/zodiac",
          },
        ],
        material: [
          {
            slug: "men-gold",
            title: "Gold",
            url: "/men/metal/18kt-gold",
          },
          {
            slug: "men-mixed-metals",
            title: "Mixed Metals",
            url: "/men/metal/mixed-metals",
          },
          {
            slug: "men-sterling-silver",
            title: "Sterling Silver",
            url: "/men/metal/sterling-silver",
          },
          {
            slug: "men-leather",
            title: "Leather",
            url: "/men/metal/leather",
          },
        ],
      },
    },
  ];

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.checkWishlist();
    this.getMenu();
  }

  getMenu() {
    this.setData({ "default.mainMenu": this.mainMenu });
  }

  checkWishlist() {
    Services.get("wishlist").then(([wishlistService]) => {
      var wishlist = wishlistService.getWishlist();
      this.setData({ "default.wishlist": wishlist });
    });
  }

  toggle() {
    this.toggleData("default.opened", {
      defaultValue: true,
    });
  }

  toggleSubMenuDrawer(index) {
    this.getComponents()
      .findById("sub-menu-drawer")
      .forEach((drawer) => {
        drawer.toggle(index);
      });
  }
}

import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Stack,
  Typography,
  IconButton,
  styled,
  Container,
  LinearProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ProductSliderView from "../../../Components/Widgets/ProductListView";

const SliderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  overflowX: "auto",
  scrollSnapType: "x mandatory",
  width: "100%",
  maxWidth: "100vw",
  scrollbarWidth: "none",
  minWidth: "0",
  msOverflowStyle: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  position: "relative",
}));

const Slide = styled(Box)(({ theme }) => ({
  flex: `0 0 calc(22%)`,
  scrollSnapAlign: "start",
  transition: "transform 0.3s ease",
  position: "relative",
  minWidth: "0",
  [theme.breakpoints.down("xl")]: {
    flex: `0 0 calc(22vw)`,
  },
  [theme.breakpoints.down("lg")]: {
    flex: `0 0 calc(22vw)`,
  },
  [theme.breakpoints.down("md")]: {
    flex: `0 0 calc(35vw)`,
  },
  [theme.breakpoints.down("sm")]: {
    flex: `0 0 calc(65vw)`,
  },
}));

const ArrowButton = styled(IconButton)(({ theme, disabled }) => ({
  fontSize: 26,
  color: "#000",
  backgroundColor: "transparent",
  width: 40,
  height: 40,
  "&:disabled": {
    backgroundColor: "transparent",
  },
  "&:hover": {
    backgroundColor: "transparent",
    opacity: 0.9,
  },
}));

export default function CollectionSlider(props) {
  const { component } = props;
  const { products = [] } = component.getData("default", {});

  const theme = useTheme();
  const sliderRef = useRef(null);

  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(100); // Start at 100%

  const checkScrollPosition = () => {
    if (!sliderRef.current) return;
    const { scrollLeft, scrollWidth, clientWidth } = sliderRef.current;

    // Calculate total visible width (scrolled + visible viewport)
    const totalVisible = scrollLeft + clientWidth;

    // Calculate percentage of total visible relative to the total scrollable width
    const progress = (totalVisible / scrollWidth) * 100;

    setScrollProgress(progress);
    setIsAtStart(scrollLeft === 0);
    setIsAtEnd(scrollLeft + clientWidth >= scrollWidth - 1);
  };

  const scrollSlider = (direction) => {
    if (!sliderRef.current) return;
    const scrollAmount = sliderRef.current.offsetWidth * 0.8;
    sliderRef.current.scrollBy({
      left: direction === "left" ? -scrollAmount : scrollAmount,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const slider = sliderRef.current;
    if (!slider) return;

    // Ensure slider starts at the beginning on load
    slider.scrollLeft = 0;

    // Attach scroll listener
    const handleScroll = () => checkScrollPosition();
    slider.addEventListener("scroll", handleScroll);

    // Check position after ensuring the DOM and styles have rendered
    const timer = setTimeout(() => {
      checkScrollPosition();
    }, 0); // Slight delay to ensure dimensions are loaded

    return () => {
      slider.removeEventListener("scroll", handleScroll);
      clearTimeout(timer);
    };
  }, [products]);

  if (products.length === 0) {
    return null;
  }

  return (
    <Box sx={{ bgcolor: "#fff", pb: { xs: 16, md: 20 } }}>
      <Container maxWidth="xl" disableGutters>
        <Typography
          align="center"
          sx={{
            ...theme.custom.titles.title,
            textDecoration: "none",
            display: "block",
            mb: 2,
          }}
        >
          Recently Viewed
        </Typography>
        <SliderContainer ref={sliderRef}>
          <Stack direction="row" spacing={{ xs: 1, sm: 2 }}>
            {products.map((product) => {
              const { _id } = product;

              return (
                <Slide key={_id}>
                  <ProductSliderView
                    {...{
                      ...props,
                      ...{
                        product,
                      },
                    }}
                  />
                </Slide>
              );
            })}
          </Stack>
        </SliderContainer>
        <Container
          maxWidth="md"
          disableGutters
          sx={{ display: { xs: "none", md: "block" }, mt: 4 }}
        >
          <Stack
            direction="row"
            spacing={8}
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <ArrowButton
              onClick={() => scrollSlider("left")}
              disabled={isAtStart}
            >
              <i className="fa-sharp fa-regular fa-chevron-left" />
            </ArrowButton>
            <LinearProgress
              variant="determinate"
              value={scrollProgress}
              sx={{ width: "100%" }}
            />
            <ArrowButton
              onClick={() => scrollSlider("right")}
              disabled={isAtEnd}
            >
              <i className="fa-sharp fa-regular fa-chevron-right" />
            </ArrowButton>
          </Stack>
        </Container>
      </Container>
    </Box>
  );
}
